<template>
  <b-modal
    id="zip-modal"
    :title="lang.modal.zip.title"
    visible
    centered
    :ok-title="lang.btn.compress"
    :ok-disabled="!submitActive"
    @ok="createArchive"
    @cancel="hideModal"
  >
    <label for="fm-zip-name">{{ lang.modal.zip.fieldName }}</label>
    <div class="input-group mb-3">
      <input
        id="fm-zip-name"
        v-model="archiveName"
        v-focus
        type="text"
        class="form-control"
        :class="{ 'is-invalid': archiveExist }"
        @keyup="validateArchiveName"
      >
      <div class="input-group-append">
        <span class="input-group-text">.zip</span>
      </div>
      <div
        v-show="archiveExist"
        class="invalid-feedback"
      >
        {{ lang.modal.zip.fieldFeedback }}
      </div>
    </div>
    <hr>
    <selected-file-list />
  </b-modal>
</template>

<script>
import { BModal } from 'bootstrap-vue'
import translate from '@/file-manager/mixins/translate'
import SelectedFileList from '../additions/SelectedFileList.vue'
import modal from '../mixins/modal'

export default {
  name: 'ZipModal',
  components: { SelectedFileList, BModal },
  mixins: [modal, translate],
  data() {
    return {
      // name for new archive
      archiveName: '',

      // archive exist
      archiveExist: false,
    }
  },
  computed: {
    /**
         * Submit button - active or no
         * @returns {string|boolean}
         */
    submitActive() {
      return this.archiveName && !this.archiveExist
    },
  },
  methods: {
    /**
         * Check the archive name if it exists or not.
         */
    validateArchiveName() {
      if (this.archiveName) {
        this.archiveExist = this.$store.getters[`fm/${this.activeManager}/fileExist`](
          `${this.archiveName}.zip`,
        )
      } else {
        this.archiveExist = false
      }
    },

    /**
         * Create new archive
         */
    createArchive() {
      this.$store.dispatch('fm/zip', `${this.archiveName}.zip`).then(() => {
        // close modal window
        this.hideModal()
      })
    },
  },
}
</script>
