import Helper from '@/file-manager/mixins/helper'

const { mapPermission } = Helper.methods

export default {
  computed: {
    /**
         * Selected disk
         * @returns {*}
         */
    selectedDisk() {
      return this.$store.getters['fm/selectedDisk']
    },

    /**
         * Selected items
         * @returns {*}
         */
    selectedItems() {
      return this.$store.getters['fm/selectedItems']
    },

    /**
         * Driver for selected disk
         * @returns {*}
         */
    selectedDiskDriver() {
      return this.$store.state.fm.disks[this.selectedDisk].driver
    },

    /**
         * Multi selection
         * @returns {boolean}
         */
    multiSelect() {
      return this.$store.getters['fm/selectedItems'].length > 1
    },

    /**
         * First item type - dir or file
         * @returns {*}
         */
    firstItemType() {
      return this.$store.getters['fm/selectedItems'][0].type
    },
  },
  methods: {
    /**
         * Can we show this item?
         * @param extension
         * @param permissions
         * @returns {boolean}
         */
    canView(extension, permissions) {
      // extension not found
      if (!extension) return false
      const hasPermission = mapPermission(permissions).includes('READ')

      return hasPermission && this.$store.state.fm.settings.imageExtensions.includes(extension.toLowerCase())
    },

    /**
         * Can we edit this file in the code editor?
         * @param extension
         * @param permissions
         * @returns {boolean}
         */
    canEdit(extension, permissions) {
      // extension not found
      if (!extension) return false
      const hasPermission = mapPermission(permissions).includes('UPDATE')

      return hasPermission && Object.keys(this.$store.state.fm.settings.textExtensions).includes(extension.toLowerCase())
    },

    /**
         * Can we delete this item?
         * @param permissions
         * @returns {boolean}
         */
    canDelete() {
      return true
    },

    /**
         * Can we play this audio file?
         * @param extension
         * @returns {boolean}
         */
    canAudioPlay(extension) {
      // extension not found
      if (!extension) return false

      return this.$store.state.fm.settings.audioExtensions.includes(extension.toLowerCase())
    },

    /**
         * Can we play this video file?
         * @param extension
         * @returns {boolean}
         */
    canVideoPlay(extension) {
      // extension not found
      if (!extension) return false

      return this.$store.state.fm.settings.videoExtensions.includes(extension.toLowerCase())
    },

    /**
         * Zip archive or not
         * @param extension
         * @returns {boolean}
         */
    isZip(extension) {
      // extension not found
      if (!extension) return false

      return extension.toLowerCase() === 'zip'
    },

    /**
         * All the files and folders can be shared
         * @param permissions
         * @returns {boolean}
         */
    canShare(extension, permissions) {
      const hasPermission = mapPermission(permissions).includes('SHARE')
      return hasPermission
    },
  },
}
