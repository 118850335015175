<template>
  <div
    ref="scrollArea"
    class="fm-disk-list"
  >
    <ul class="list-inline">
      <li
        v-for="(disk, index) in disks"
        :key="index"
        class="list-inline-item"
      >
        <span
          class="badge"
          :class="[disk === selectedDisk ? 'bg-secondary' : 'bg-light text-dark']"
          @click="selectDisk(disk)"
        >
          <i class="bi bi-hdd" /> {{ disk }}
        </span>
      </li>
    </ul>
  </div>
</template>

<script>
import Scrollbar from 'smooth-scrollbar'

export default {
  name: 'DiskList',
  props: {
    // manager name - left or right
    manager: { type: String, required: true },
  },
  computed: {
    /**
         * Disk list
         * @returns {Array}
         */
    disks() {
      return this.$store.getters['fm/diskList']
    },

    /**
         * Selected Disk for this manager
         * @returns {any}
         */
    selectedDisk() {
      return this.$store.state.fm[this.manager].selectedDisk
    },
  },
  mounted() {
    Scrollbar.init(this.$refs.scrollArea, {
      renderByPixels: true,
      continuousScrolling: true,
      alwaysShowTracks: true,
    })
  },
  methods: {
    /**
         * Select disk
         * @param disk
         */
    selectDisk(disk) {
      if (this.selectedDisk !== disk) {
        this.$store.dispatch('fm/selectDisk', {
          disk,
          manager: this.manager,
        })
      }
    },
  },
}
</script>

<style lang="scss">
.fm-disk-list {
    ul.list-inline {
        margin-bottom: 0.5rem;
    }

    .badge.bg-light {
        cursor: pointer;
    }
}
</style>
