<template>
  <div class="modal-content fm-modal-preview">
    <div class="modal-header">
      <h5 class="modal-title w-75 text-truncate">
        {{ showCropperModule ? lang.modal.cropper.title : lang.modal.preview.title }}
        <small class="text-muted pl-3">{{ selectedItem.basename }}</small>
      </h5>
      <feather-icon
        icon="XIcon"
        class="cursor-pointer"
        @click="hideModal"
      />
    </div>
    <div class="modal-body text-center">
      <template v-if="showCropperModule">
        <cropper-module
          :img-src="imgSrc"
          :max-height="maxHeight"
          @closeCropper="closeCropper"
        />
      </template>
      <transition
        v-else
        name="fade"
        mode="out-in"
      >
        <div
          v-if="!imgSrc"
          class="spinner-border spinner-border-lg text-muted my-2"
        />
        <img
          v-else
          :src="imgSrc"
          :alt="selectedItem.basename"
          :style="{ 'max-height': maxHeight + 'px' }"
        >
      </transition>
    </div>
    <div
      v-if="showFooter"
      class="d-flex justify-content-between"
    >
      <span class="d-block">
        <button
          type="button"
          class="btn btn-primary"
          :title="lang.modal.cropper.title"
          @click="showCropperModule = true"
        >
          <i class="bi bi-crop" />
        </button>
      </span>
      <span class="d-block">
        <button
          type="button"
          class="btn btn-light"
          @click="hideModal"
        >{{ lang.btn.cancel }}</button>
      </span>
    </div>
  </div>
</template>

<script>
import translate from '@/file-manager/mixins/translate'
import helper from '@/file-manager/mixins/helper'
import GET from '@/file-manager/http/get'
import modal from '../mixins/modal'
import CropperModule from '../additions/CropperModule.vue'

export default {
  name: 'PreviewModal',
  components: { CropperModule },
  mixins: [modal, translate, helper],
  data() {
    return {
      showCropperModule: false,
      imgSrc: null,
    }
  },
  computed: {
    /**
         * Authorization required
         * @return {*}
         */
    auth() {
      return this.$store.getters['fm/settings/authHeader']
    },

    /**
         * Selected disk
         * @returns {*}
         */
    selectedDisk() {
      return this.$store.getters['fm/selectedDisk']
    },

    /**
         * Selected file
         * @returns {*}
         */
    selectedItem() {
      return this.$store.getters['fm/selectedItems'][0]
    },

    /**
         * Show modal footer
         * @return boolean
         */
    showFooter() {
      return this.canCrop(this.selectedItem.extension) && !this.showCropperModule
    },

    /**
         * Calculate the max height for image
         * @returns {number}
         */
    maxHeight() {
      if (this.$store.state.fm.modal.modalBlockHeight) {
        return this.$store.state.fm.modal.modalBlockHeight - 170
      }

      return 300
    },
  },
  mounted() {
    this.loadImage()
  },
  methods: {
    /**
         * Can we crop this image?
         * @param extension
         * @returns {boolean}
         */
    canCrop(extension) {
      return this.$store.state.fm.settings.cropExtensions.includes(extension.toLowerCase())
    },

    /**
         * Close cropper
         */
    closeCropper() {
      this.showCropperModule = false
      this.loadImage()
    },

    /**
         * Load image
         */
    loadImage() {
      // if authorization required
      if (this.auth) {
        GET.preview(this.selectedDisk, this.selectedItem.path).then(response => {
          const mimeType = response.headers['content-type'].toLowerCase()
          const imgBase64 = Buffer.from(response.data, 'binary').toString('base64')

          this.imgSrc = `data:${mimeType};base64,${imgBase64}`
        })
      } else {
        this.imgSrc = `${this.$store.getters['fm/settings/baseUrl']}preview?disk=${
          this.selectedDisk
        }&path=${encodeURIComponent(this.selectedItem.path)}&v=${this.selectedItem.timestamp}`
      }
    },
  },
}
</script>

<style lang="scss">
.fm-modal-preview {
    .modal-body {
        padding: 0;

        img {
            max-width: 100%;
        }
    }

    & > .d-flex {
        padding: 1rem;
        border-top: 1px solid #e9ecef;
    }
}
</style>
