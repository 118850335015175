<template>
  <div
    id="fm"
    class="fm d-flex flex-column"
    :class="{ 'fm-full-screen': fullScreen }"
  >
    <navbar-block />
    <div class="fm-body d-flex">
      <notification-block />
      <context-menu />
      <modal-block v-if="showModal" />
      <template v-if="windowsConfig === 1">
        <left-manager
          class="col"
          manager="left"
        />
      </template>
      <template v-else-if="windowsConfig === 2">
        <folder-tree class="col-4 col-md-3" />
        <left-manager
          class="col-8 col-md-9"
          manager="left"
        />
      </template>
      <template v-else-if="windowsConfig === 3">
        <left-manager
          class="col-12 col-sm-6"
          manager="left"
          @click.native="selectManager('left')"
          @contextmenu.native="selectManager('left')"
        />
        <!-- <right-manager
          class="col-12 col-sm-6"
          manager="right"
          @click.native="selectManager('right')"
          @contextmenu.native="selectManager('right')"
        /> -->
      </template>
    </div>
    <info-block />
  </div>
</template>

<script>
import { mapState } from 'vuex'
// Axios
import useApollo from '@/plugins/graphql/useApollo'
import HTTP from './http/axios'
import EventBus from './emitter'
// Components
import NavbarBlock from './components/blocks/NavbarBlock.vue'
import FolderTree from './components/tree/FolderTree.vue'
import LeftManager from './components/manager/Manager.vue'
// import RightManager from './components/manager/Manager.vue'
import ModalBlock from './components/modals/ModalBlock.vue'
import InfoBlock from './components/blocks/InfoBlock.vue'
import ContextMenu from './components/blocks/ContextMenu.vue'
import NotificationBlock from './components/blocks/NotificationBlock.vue'
// Mixins
import translate from './mixins/translate'

export default {
  name: 'FileManager',
  components: {
    NavbarBlock,
    FolderTree,
    LeftManager,
    // RightManager,
    ModalBlock,
    InfoBlock,
    ContextMenu,
    NotificationBlock,
  },
  mixins: [translate],
  props: {
    /**
         * LFM manual settings
         */
    settings: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      interceptorIndex: {
        request: null,
        response: null,
      },
    }
  },
  computed: {
    ...mapState('fm', {
      windowsConfig: state => state.settings.windowsConfig,
      activeManager: state => state.settings.activeManager,
      showModal: state => state.modal.showModal,
      fullScreen: state => state.settings.fullScreen,
    }),
    ...mapState('project', ['projectUsers']),
  },
  created() {
    // manual settings
    this.$store.commit('fm/settings/manualSettings', this.settings)

    // initiate Axios
    this.$store.commit('fm/settings/initAxiosSettings')
    this.setAxiosConfig()
    this.requestInterceptor()
    this.responseInterceptor()
    if (!this.projectUsers.length) this.fetchProjectUsers()

    // initialize app settings
    this.$store.dispatch('fm/initializeApp')
  },
  destroyed() {
    // reset state
    this.$store.dispatch('fm/resetState')

    // delete events
    EventBus.all.clear()

    // eject interceptors
    HTTP.interceptors.request.eject(this.interceptorIndex.request)
    HTTP.interceptors.response.eject(this.interceptorIndex.response)
  },
  methods: {
    fetchProjectUsers() {
      const params = {
        projectUid: this.$store.state.project.selectedProject,
      }
      useApollo.users.getProjectUsers(params).then(response => {
        this.$store.dispatch('project/STORE_PROJECT_USERS', response.data.users.data)
      })
    },
    /**
         * Axios default config
         */
    setAxiosConfig() {
      HTTP.defaults.baseURL = this.$store.getters['fm/settings/baseUrl']
      HTTP.defaults.headers = this.$store.getters['fm/settings/headers']
    },

    /**
         * Add axios request interceptor
         */
    requestInterceptor() {
      this.interceptorIndex.request = HTTP.interceptors.request.use(
        config => {
          // loading spinner +
          this.$store.commit('fm/messages/addLoading')

          return config
        },
        error => {
          // loading spinner -
          this.$store.commit('fm/messages/subtractLoading')
          return Promise.reject(error)
        },
      )
    },

    /**
         * Add axios response interceptor
         */
    responseInterceptor() {
      this.interceptorIndex.response = HTTP.interceptors.response.use(
        response => {
          // loading spinner -
          this.$store.commit('fm/messages/subtractLoading')

          // create notification, if find message text
          if (Object.prototype.hasOwnProperty.call(response.data, 'result')) {
            if (response.data.result.message) {
              const message = Object.prototype.hasOwnProperty.call(this.lang.response, response.data.result.message)
                ? this.lang.response[response.data.result.message]
                : response.data.result.message

              switch (response.data.result.status) {
                case 'success':
                  this.showSuccess(message)
                  break
                case 'error':
                  this.showError(message)
                  break
                default:
              }

              // // show notification
              // EventBus.emit('addNotification', message)

              // // set action result
              // this.$store.commit('fm/messages/setActionResult', message)
            }
          }

          return response
        },
        error => {
          // loading spinner -
          this.$store.commit('fm/messages/subtractLoading')

          const errorMessage = {
            status: 0,
            message: '',
          }

          const errorNotificationMessage = {
            status: 'error',
            message: '',
          }

          // add message
          if (error.response) {
            errorMessage.status = error.response.status

            if (error.response.data.message) {
              const trMessage = Object.prototype.hasOwnProperty.call(
                this.lang.response,
                error.response.data.message,
              )
                ? this.lang.response[error.response.data.message]
                : error.response.data.message

              errorMessage.message = trMessage
              errorNotificationMessage.message = trMessage
            } else {
              errorMessage.message = error.response.statusText
              errorNotificationMessage.message = error.response.statusText
            }
          } else if (error.request) {
            errorMessage.status = error.request.status
            errorMessage.message = error.request.statusText || 'Network error'
            errorNotificationMessage.message = error.request.statusText || 'Network error'
          } else {
            errorMessage.message = error.message
            errorNotificationMessage.message = error.message
          }

          // set error message
          this.$store.commit('fm/messages/setError', errorMessage)

          // show notification
          EventBus.emit('addNotification', errorNotificationMessage)

          return Promise.reject(error)
        },
      )
    },

    /**
         * Select manager (when shown 2 file manager windows)
         * @param managerName
         */
    selectManager(managerName) {
      if (this.activeManager !== managerName) {
        this.$store.commit('fm/setActiveManager', managerName)
      }
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/base/pages/file-manager.scss";
</style>
