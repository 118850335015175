const PERMISSIONS = {
  READ: 1,
  UPDATE: 2,
  CREATE: 4,
  DELETE: 8,
  SHARE: 16,
  ALL: 31,
}

export default {
  PERMISSIONS,
}
