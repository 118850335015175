<template>
  <div>
    <file-manager :settings="settings" />
  </div>
</template>

<script>
import FileManager from '@/file-manager/FileManager.vue'

export default {
  components: {
    FileManager,
  },
  computed: {
    settings() {
      return {
        // axios headers
        headers: {
          'X-Requested-With': 'XMLHttpRequest',
          Authorization: `Bearer ${window.localStorage.getItem('accessToken')}`,
        },
        baseUrl: `${process.env.VUE_APP_GEC_API}/fm`,
        windowsConfig: 2,
      }
    },
  },
}
</script>
