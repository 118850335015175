<template>
  <b-modal
    id="clipboard-modal"
    :title="lang.clipboard.title"
    visible
    centered
    :ok-title="lang.btn.clear"
    ok-variant="danger"
    :ok-disabled="!clipboard.type"
    @ok="resetClipboard"
    @hidden="hideModal"
  >
    <template v-if="clipboard.type">
      <div class="d-flex justify-content-between">
        <div class="w-75 text-truncate">
          <span
            class="badge bg-light text-dark"
          ><i class="bi bi-hdd pe-1" /> {{ clipboard.disk }}</span>
        </div>
        <div class="text-right text-muted">
          <span :title="`${lang.clipboard.actionType} - ${lang.clipboard[clipboard.type]}`">
            <i
              v-if="clipboard.type === 'copy'"
              class="bi bi-files"
            />
            <i
              v-else
              class="bi bi-scissors"
            />
          </span>
        </div>
      </div>
      <hr>
      <div
        v-for="(dir, index) in directories"
        :key="`d-${index}`"
        class="d-flex justify-content-between"
      >
        <div class="w-75 text-truncate">
          <span><i class="bi bi-folder" /> {{ dir.name }}</span>
        </div>
        <div class="text-right">
          <button
            type="button"
            class="btn-close border-0 text-danger"
            :title="lang.btn.delete"
            @click="deleteItem('directories', dir.path)"
          >
            <feather-icon icon="Trash2Icon" />
          </button>
        </div>
      </div>
      <div
        v-for="(file, index) in files"
        :key="`f-${index}`"
        class="d-flex justify-content-between"
      >
        <div class="w-75 text-truncate">
          <span><i
            class="bi"
            :class="file.icon"
          /> {{ file.name }}</span>
        </div>
        <div class="text-right">
          <button
            type="button"
            class="btn-close border-0 text-danger"
            :title="lang.btn.delete"
            @click="deleteItem('files', file.path)"
          >
            <feather-icon icon="Trash2Icon" />
          </button>
        </div>
      </div>
    </template>
    <template v-else>
      <span>{{ lang.clipboard.none }}</span>
    </template>
  </b-modal>
</template>

<script>
import { BModal } from 'bootstrap-vue'
import translate from '@/file-manager/mixins/translate'
import helper from '@/file-manager/mixins/helper'
import modal from '../mixins/modal'

export default {
  name: 'ClipboardModal',
  components: { BModal },
  mixins: [modal, translate, helper],
  computed: {
    /**
         * Clipboard state
         * @returns {*}
         */
    clipboard() {
      return this.$store.state.fm.clipboard
    },

    /**
         * Paths and names for directories
         * @returns {{path: *, name: *}[]}
         */
    directories() {
      return this.$store.state.fm.clipboard.directories.map(item => ({
        path: item,
        name: item.split('/').slice(-1)[0],
      }))
    },

    /**
         * File names, paths and icons
         * @returns {{path: *, name: *, icon: *}[]}
         */
    files() {
      return this.$store.state.fm.clipboard.files.map(item => {
        const name = item.split('/').slice(-1)[0]
        return {
          path: item,
          name,
          icon: this.extensionToIcon(name.split('.').slice(-1)[0]),
        }
      })
    },
  },
  methods: {
    /**
         * Delete item from clipboard
         * @param type
         * @param path
         */
    deleteItem(type, path) {
      this.$store.commit('fm/truncateClipboard', { type, path })
    },

    /**
         * Reset clipboard
         */
    resetClipboard() {
      this.$store.commit('fm/resetClipboard')
    },
  },
}
</script>

<style lang="scss">
.fm-modal-clipboard {
    .modal-body .far {
        padding-right: 0.5rem;
    }
}
</style>
