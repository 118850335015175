<template>
  <b-modal
    :title="lang.modal.rename.title"
    :ok-title="lang.btn.submit"
    :ok-disabled="isProcessing"
    :cancel-disabled="isProcessing"
    visible
    centered
    @ok="rename"
    @hidden="hideModal"
  >
    <b-overlay :show="isProcessing">
      <div class="form-group">
        <b-form-group :label="lang.modal.rename.fieldName">
          <b-form-input
            id="fm-input-rename"
            v-model="name"
            v-focus
            type="text"
            :class="{ 'is-invalid': checkName }"
            @keyup="validateName"
            @keypress.enter="submitDisable ? '' : rename()"
          />
          <div
            v-show="checkName"
            class="invalid-feedback"
          >
            {{ lang.modal.rename.fieldFeedback }}
            {{ directoryExist ? ` - ${lang.modal.rename.directoryExist}` : '' }}
            {{ fileExist ? ` - ${lang.modal.rename.fileExist}` : '' }}
          </div>
        </b-form-group>
      </div>
    </b-overlay>
  </b-modal>
</template>

<script>
import translate from '@/file-manager/mixins/translate'
import {
  BOverlay, BModal, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import modal from '../mixins/modal'

export default {
  name: 'RenameModal',
  components: {
    BOverlay, BModal, BFormGroup, BFormInput,
  },
  mixins: [modal, translate],
  data() {
    return {
      name: '',
      directoryExist: false,
      fileExist: false,
      isProcessing: false,
    }
  },
  computed: {
    /**
         * Selected item
         * @returns {*}
         */
    selectedItem() {
      return this.$store.getters[`fm/${this.activeManager}/selectedList`][0]
    },

    /**
         * Check new name
         * @returns {boolean}
         */
    checkName() {
      return this.directoryExist || this.fileExist || !this.name
    },

    /**
         * Submit button disable
         * @returns {*|boolean}
         */
    submitDisable() {
      return this.checkName || this.name === this.selectedItem.basename
    },
  },
  mounted() {
    // initiate item name
    this.name = this.selectedItem.basename
  },
  methods: {
    /**
         * Validate item name
         */
    validateName() {
      if (this.name !== this.selectedItem.basename) {
        // if item - folder
        if (this.selectedItem.type === 'dir') {
          // check folder name matches
          this.directoryExist = this.$store.getters[`fm/${this.activeManager}/directoryExist`](this.name)
        } else {
          // check file name matches
          this.fileExist = this.$store.getters[`fm/${this.activeManager}/fileExist`](this.name)
        }
      }
    },

    /**
         * Rename item
         */
    rename() {
      this.isProcessing = true
      // create new name with path
      const newName = this.selectedItem.dirname ? `${this.selectedItem.dirname}/${this.name}` : this.name

      this.$store
        .dispatch('fm/rename', {
          type: this.selectedItem.type,
          newName,
          oldName: this.selectedItem.path,
        })
        .then(() => {
          // close modal window
          this.hideModal()
        }).finally(() => {
          this.isProcessing = false
        })
    },
  },
}
</script>
