<template>
  <b-overlay :show="isProcessing">
    <b-modal
      id="file-delete-modal"
      :title="lang.modal.delete.title"
      ok-title="Delete"
      ok-variant="danger"
      visible
      centered
      @ok="deleteItems"
      @hidden="hideModal"
    >
      <div v-if="selectedItems.length">
        <selected-file-list />
      </div>
      <div v-else>
        <span class="text-danger">{{ lang.modal.delete.noSelected }}</span>
      </div>
    </b-modal>
    <!-- <div class="modal-content fm-modal-delete">
      <div class="modal-header">
        <h5 class="modal-title">
          {{ lang.modal.delete.title }}
        </h5>
        <feather-icon
          icon="XIcon"
          class="cursor-pointer"
          @click="hideModal"
        />
      </div>
      <div class="modal-body">
        <div v-if="selectedItems.length">
          <selected-file-list />
        </div>
        <div v-else>
          <span class="text-danger">{{ lang.modal.delete.noSelected }}</span>
        </div>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          class="btn btn-danger"
          @click="deleteItems"
        >
          {{ lang.modal.delete.title }}
        </button>
        <button
          type="button"
          class="btn btn-light"
          @click="hideModal"
        >
          {{ lang.btn.cancel }}
        </button>
      </div>
    </div> -->
  </b-overlay>
</template>

<script>
import { BOverlay, BModal } from 'bootstrap-vue'
import translate from '@/file-manager/mixins/translate'
import SelectedFileList from '../additions/SelectedFileList.vue'
import modal from '../mixins/modal'

export default {
  name: 'DeleteModal',
  components: { SelectedFileList, BOverlay, BModal },
  mixins: [modal, translate],
  data() {
    return {
      isProcessing: false,
    }
  },
  computed: {
    /**
         * Files and folders for deleting
         * @returns {*}
         */
    selectedItems() {
      return this.$store.getters['fm/selectedItems']
    },
  },
  methods: {
    /**
         * Delete selected directories and files
         */
    deleteItems() {
      this.isProcessing = true
      // create items list for delete
      const items = this.selectedItems.map(item => ({
        path: item.path,
        type: item.type,
      }))

      this.$store.dispatch('fm/delete', items).then(() => {
        this.hideModal()
      }).finally(() => {
        this.isProcessing = false
      })
    },
  },
}
</script>
