<template>
  <b-popover
    :target="target"
    placement="top"
    class="share-details position-relative"
  >
    <b-button
      class="close-button rounded-circle position-absolute top-0 right-0 p-0"
      variant="danger"
      size="sm"
      @click="closePopover"
    >
      <feather-icon
        icon="XIcon"
        size="20"
      />
    </b-button>
    <div v-if="selectedUser">
      <div class="d-flex align-items-center">
        <b-avatar
          class="mr-1 mt-1"
          :src="getAvatar(selectedUser.avatar)"
          size="48"
        />

        <div>
          <h5 class="mt-1 mb-0">
            {{ selectedUser.name ? selectedUser.name : 'Anyone with link' }}
          </h5>
          <small
            v-if="selectedUser.email"
            class="mb-0"
          >
            {{ selectedUser.email }}
          </small>
          <b-badge
            v-if="selectedUser.url"
            size="sm"
            class="cursor-pointer w-100"
            variant="wz-teal"
            @click="copyLink(selectedUser.url)"
          >
            <feather-icon :icon="copied ? 'CheckCircleIcon' : 'CopyIcon'" />
            {{ copied ? 'Link Copied' : 'Copy Link' }}
          </b-badge>
        </div>
      </div>

      <p class="text-dark font-weight-bold mt-1">
        Granted Permissions:
      </p>
      <div class="d-flex">
        <div
          v-for="permission in permissionOptions"
          :key="permission.value"
          class="text-dark mr-3 text-center"
          :class="{ 'd-none': !grantedPermissions.includes(permission.label.toUpperCase()) }"
        >
          <b-avatar :variant="getVariant(permission.label)">
            <feather-icon :icon="permission.icon" />
          </b-avatar>
          <p>{{ permission.label }}</p>
        </div>
      </div>
    </div>
  </b-popover>
</template>

<script>
import {
  BPopover, BAvatar, BBadge, BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BPopover,
    BAvatar,
    BBadge,
    BButton,
  },
  props: {
    selectedUser: {
      type: Object,
      default: () => null,
    },
    grantedPermissions: {
      type: Array,
      required: true,
    },
    permissionOptions: {
      type: Array,
      required: true,
    },
    target: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      copied: false,
    }
  },
  methods: {
    copyLink(url) {
      navigator.clipboard.writeText(url)
      this.copied = true
      setTimeout(() => {
        this.copied = false
      }, 5000)
    },
    closePopover() {
      this.$root.$emit('bv::hide::popover')
      this.$emit('close')
    },
    getVariant(permission) {
      switch (permission) {
        case 'Read':
          return 'primary'
        case 'Update':
          return 'warning'
        case 'Share':
          return 'wz-teal'
        default:
          return 'secondary'
      }
    },
  },
}
</script>

<style lang="scss">
#share-item-modal {
  .popover-body {
    min-width: 250px;
  }
  .close-button {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
