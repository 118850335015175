<template>
  <div class="fm-tree">
    <div
      class="fm-tree-disk sticky-top"
      :style="'z-index: 0;'"
    >
      <i class="bi bi-hdd" /> {{ selectedDisk }}
    </div>
    <branch :parent-id="0" />
  </div>
</template>

<script>
import Branch from './TreeBranch.vue'

export default {
  name: 'FolderTree',
  components: {
    branch: Branch,
  },
  computed: {
    /**
         * Selected Disk
         * @returns {*}
         */
    selectedDisk() {
      return this.$store.getters['fm/selectedDisk']
    },
  },
}
</script>
