<template>
  <div class="custom-radio-buttons">
    <b-form-group :label="label">
      <b-form-radio-group
        v-model="input"
        button-variant="outline-primary"
        buttons
        :disabled="disabled"
        @change="$emit('input', input)"
      >
        <b-form-radio
          v-for="option in options"
          :key="option.value"
          :value="option.value"
          :disabled="option.disabled"
          :name="name"
          class="d-flex flex-column justify-content-center align-items-center"
        >
          <feather-icon
            v-if="iconType === 'feather'"
            class="icon"
            :icon="option.icon"
            :size="iconSize"
          />
          <i
            v-else-if="iconType === 'fa'"
            class="icon"
            :class="option.icon"
            :style="`font-size: ${iconSize}px`"
          />
          <p class="text mb-0">
            {{ option.label }}
          </p>
          <small v-if="option.extra">{{ option.extra }}</small>
        </b-form-radio>
      </b-form-radio-group>
    </b-form-group>
  </div>
</template>

<script>
import { BFormGroup, BFormRadioGroup, BFormRadio } from 'bootstrap-vue'

export default {
  components: {
    BFormGroup,
    BFormRadio,
    BFormRadioGroup,
  },
  props: {
    label: {
      type: String,
      default: () => '',
    },
    options: {
      type: Array,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    iconType: {
      type: String,
      default: () => 'feather',
    },
    iconSize: {
      type: Number,
      default: () => 28,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    value: {
      type: [String, Number, Object],
      default: () => null,
    },
  },
  data() {
    return {
      input: this.options[0].value,
    }
  },
  watch: {
    value: {
      handler(val) {
        if (val && val !== this.input) this.input = val
      },
    },
  },
}
</script>

<style lang="scss">
.custom-radio-buttons {
  .btn {
    width: 140px;
  }
  .active {
    background: #3D61AD !important;
    .text,
    .icon {
      color: #e6e6e6 !important;
      font-weight: 700;
    }
  }
  .text {
    margin-top: 4px;
  }
}
</style>
