<template>
  <div>
    <b-skeleton-wrapper
      class="pt-1 pl-2"
      :loading="loading"
    >
      <template #loading>
        <b-skeleton
          class="mb-2"
          width="85%"
          height="15px"
        />
        <b-skeleton
          class="mb-2"
          width="85%"
          height="15px"
        />
        <b-skeleton
          class="mb-2"
          width="85%"
          height="15px"
        />
        <b-skeleton
          class="mb-2"
          width="85%"
          height="15px"
        />
        <b-skeleton
          class="mb-2"
          width="85%"
          height="15px"
        />
      </template>
      <slot />
    </b-skeleton-wrapper>
  </div>
</template>

<script>
import { BSkeleton, BSkeletonWrapper } from 'bootstrap-vue'

export default {
  components: {
    BSkeletonWrapper,
    BSkeleton,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
}

</script>
