<template>
  <div ref="scrollArea">
    <div class="fm-table">
      <table class="table table-sm">
        <thead>
          <tr>
            <th
              class="w-65"
              @click="sortBy('name')"
            >
              {{ lang.manager.table.name }}
              <template v-if="sortSettings.field === 'name'">
                <i
                  v-show="sortSettings.direction === 'down'"
                  class="bi bi-sort-down"
                />
                <i
                  v-show="sortSettings.direction === 'up'"
                  class="bi bi-sort-up"
                />
              </template>
            </th>
            <th
              class="w-10"
              @click="sortBy('size')"
            >
              {{ lang.manager.table.size }}
              <template v-if="sortSettings.field === 'size'">
                <i
                  v-show="sortSettings.direction === 'down'"
                  class="bi bi-sort-down"
                />
                <i
                  v-show="sortSettings.direction === 'up'"
                  class="bi bi-sort-up"
                />
              </template>
            </th>
            <th
              class="w-10"
              @click="sortBy('type')"
            >
              {{ lang.manager.table.type }}
              <template v-if="sortSettings.field === 'type'">
                <i
                  v-show="sortSettings.direction === 'down'"
                  class="bi bi-sort-down"
                />
                <i
                  v-show="sortSettings.direction === 'up'"
                  class="bi bi-sort-up"
                />
              </template>
            </th>
            <th
              class="w-auto"
              @click="sortBy('date')"
            >
              {{ lang.manager.table.date }}
              <template v-if="sortSettings.field === 'date'">
                <i
                  v-show="sortSettings.direction === 'down'"
                  class="bi bi-sort-down"
                />
                <i
                  v-show="sortSettings.direction === 'up'"
                  class="bi bi-sort-up"
                />
              </template>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="!isRootPath">
            <td
              colspan="4"
              class="fm-content-item"
              @click="!showLoader ? levelUp() : ''"
            >
              <i class="bi bi-arrow-90deg-up" />
            </td>
          </tr>
          <tr
            v-for="(directory, index) in directories"
            :key="`d-${index}`"
            :class="{ 'table-secondary': checkSelect('directories', directory.path) }"
            @click="selectItem('directories', directory.path, $event)"
            @contextmenu.prevent="contextMenu(directory, $event)"
          >
            <td
              class="fm-content-item unselectable text-primary"
              :class="acl && directory.acl === 0 ? 'text-hidden' : ''"
              @dblclick="selectDirectory(directory.path)"
            >
              <i class="bi bi-folder" />
              <span class="text-secondary">
                {{ directory.basename }}
              </span>
              <feather-icon
                v-if="directory['share-types']"
                v-b-tooltip.hover="'Shared Folder'"
                class="link-icon text-primary"
                icon="Share2Icon"
              />
              <b-avatar
                v-if="directory['mount-type'] === 'shared' && directory['owner-id'] !== self.email"
                v-b-tooltip.hover="directory['owner-display-name']"
                variant="primary"
                class="float-right"
                size="20"
              >
                {{ directory['owner-display-name'].charAt(0).toUpperCase() }}
              </b-avatar>
            </td>
            <td />
            <td>{{ lang.manager.table.folder }}</td>
            <td>
              {{ timestampToDate(directory.timestamp) }}
            </td>
          </tr>
          <tr
            v-for="(file, index) in files"
            :key="`f-${index}`"
            :class="{ 'table-secondary': checkSelect('files', file.path) }"
            @click="selectItem('files', file.path, $event)"
            @dblclick="selectAction(file.path, file.extension)"
            @contextmenu.prevent="contextMenu(file, $event)"
          >
            <td
              class="fm-content-item unselectable text-primary"
              :class="acl && file.acl === 0 ? 'text-hidden' : ''"
            >
              <i
                class="bi"
                :class="extensionToIcon(file.extension)"
              />
              <span class="text-secondary">
                {{ file.filename ? file.filename : file.basename }}
              </span>

              <feather-icon
                v-if="file['share-types']"
                v-b-tooltip.hover="'Shared File'"
                class="link-icon text-primary"
                icon="Share2Icon"
              />
              <b-avatar
                v-if="file['mount-type'] === 'shared' && file['owner-id'] !== self.email"
                v-b-tooltip.hover="file['owner-display-name']"
                variant="primary"
                class="float-right"
                size="20"
              >
                {{ file['owner-display-name'].charAt(0).toUpperCase() }}
              </b-avatar>
            </td>
            <td>{{ bytesToHuman(file.size) }}</td>
            <td>
              {{ file.extension }}
            </td>
            <td>
              {{ timestampToDate(file.timestamp) }}
            </td>
          </tr>
        </tbody>
      </table>
      <div
        v-if="showLoader"
        class="text-center"
      >
        <b-spinner />
      </div>
      <div
        v-else-if="!files.length && !directories.length"
        class="text-center mt-2 w-100"
      >
        <p class="text-muted">
          Empty Folder
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { BSpinner, BAvatar, VBTooltip } from 'bootstrap-vue'
import translate from '@/file-manager/mixins/translate'
import helper from '@/file-manager/mixins/helper'
import Scrollbar from 'smooth-scrollbar'
import { mapGetters } from 'vuex'
import { getUserData } from '@/auth/utils'
import managerHelper from './mixins/manager'

export default {
  name: 'TableView',
  components: { BSpinner, BAvatar },
  directives: { 'b-tooltip': VBTooltip },
  mixins: [translate, helper, managerHelper],
  props: {
    manager: { type: String, required: true },
  },
  data() {
    return {
      scrollbar: {},
      self: getUserData(),
    }
  },
  computed: {
    ...mapGetters('fm/tree', ['selectedDirectory']),
    isLoading() {
      return this.$store.state.fm.messages.loading !== 0
    },
    showLoader() {
      return this.isLoading && !(this.directories.length || this.files.length)
    },
    /**
         * Sort settings
         * @returns {*}
         */
    sortSettings() {
      return this.$store.state.fm[this.manager].sort
    },
  },
  watch: {
    selectedDirectory() {
      this.scrollbar.scrollTo(0, 0)
    },
  },
  mounted() {
    this.scrollbar = Scrollbar.init(this.$refs.scrollArea, {
      renderByPixels: true,
      continuousScrolling: true,
    })
  },
  methods: {
    /**
         * Sort by field
         * @param field
         */
    sortBy(field) {
      this.$store.dispatch(`fm/${this.manager}/sortBy`, { field, direction: null })
    },
  },
}
</script>
