<template>
  <b-modal
    id="fm-status-modal"
    :title="lang.modal.status.title"
    visible
    centered
    :ok-title="lang.btn.clear"
    ok-variant="danger"
    :ok-disabled="!errors.length"
    @ok="clearErrors"
    @hidden="hideModal"
  >
    <div v-if="errors.length">
      <ul class="list-unstyled">
        <li
          v-for="(item, index) in errors"
          :key="index"
        >
          {{ item.status }} - {{ item.message }}
        </li>
      </ul>
    </div>
    <div v-else>
      <span>{{ lang.modal.status.noErrors }}</span>
    </div>
  </b-modal>
</template>

<script>
import { BModal } from 'bootstrap-vue'
import translate from '@/file-manager/mixins/translate'
import modal from '../mixins/modal'

export default {
  name: 'StatusModal',
  components: { BModal },
  mixins: [modal, translate],
  computed: {
    /**
         * Application errors
         * @returns {any}
         */
    errors() {
      return this.$store.state.fm.messages.errors
    },
  },
  methods: {
    /**
         * Clear all errors
         */
    clearErrors() {
      this.$store.commit('fm/messages/clearErrors')
    },
  },
}
</script>
