<template>
  <b-modal
    id="fm-audio-player-modal"
    :title="lang.modal.audioPlayer.title"
    visible
    centered
    hide-footer
    @hidden="hideModal"
  >
    <vue-plyr :options="playerOptions">
      <audio
        ref="fmAudio"
        controls
      >
        <source :src="audioSource">
      </audio>
    </vue-plyr>
  </b-modal>
</template>

<script>
import { BModal } from 'bootstrap-vue'
import VuePlyr from 'vue-plyr'
import translate from '@/file-manager/mixins/translate'
import modal from '../mixins/modal'
import 'vue-plyr/dist/vue-plyr.css'

export default {
  name: 'AudioPlayerModal',
  components: { VuePlyr, BModal },
  mixins: [modal, translate],
  data() {
    return {
      playerOptions: {
        tooltips: {
          controls: true,
          seek: true,
        },
        speed: {
          selected: 1,
          options: [0.5, 0.75, 1, 1.25, 1.5, 2],
        },
      },
      playingIndex: 0,
      status: 'paused',
      audioSource: null,
    }
  },
  computed: {
    /**
         * Selected disk
         * @returns {*}
         */
    selectedDisk() {
      return this.$store.getters['fm/selectedDisk']
    },

    /**
         * Audio files list
         * @returns {*}
         */
    audioFiles() {
      return this.$store.getters['fm/selectedItems']
    },
  },
  mounted() {
    this.audioSource = `${this.$store.getters['fm/settings/baseUrl']}/stream-file?disk=${
      this.selectedDisk
    }&path=${encodeURIComponent(this.audioFiles[this.playingIndex].path)}&token=${localStorage.getItem('accessToken')}`
  },
  methods: {
    /**
         * Select another audio track
         * @param index
         */
    selectTrack(index) {
      if (this.player.playing) {
        // stop playing
        this.player.stop()
      }
      // load new source
      this.setSource(index)
      // start play
      this.player.play()

      this.playingIndex = index
    },

    /**
         * Set source to audio player
         * @param index
         */
    setSource(index) {
      this.player.source = {
        type: 'audio',
        title: this.audioFiles[index].filename,
        sources: [
          {
            src: `${this.$store.getters['fm/settings/baseUrl']}stream-file?disk=${
              this.selectedDisk
            }&path=${encodeURIComponent(this.audioFiles[index].path)}`,
            type: `audio/${this.audioFiles[index].extension}`,
          },
        ],
      }
    },

    /**
         * Play/Pause
         */
    togglePlay() {
      this.player.togglePlay()
    },
  },
}
</script>

<style lang="scss">

.fm-modal-audio-player {
    .bi.bi-play-fill {
        color: gray;
        opacity: 0.1;
        cursor: pointer;

        &:hover {
            opacity: 0.5;
        }

        &.active {
            opacity: 1;
            color: deepskyblue;
        }
    }

    .bi.bi-pause-fill {
        color: gray;
        opacity: 0.5;
        cursor: pointer;
    }
}
</style>
