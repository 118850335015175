<template>
  <b-modal
    id="fm-video-player-modal"
    :title="`${lang.modal.videoPlayer.title} - ${videoFile.basename}`"
    size="lg"
    visible
    centered
    hide-footer
    @hidden="hideModal"
  >
    <div class="modal-body">
      <vue-plyr :options="playerOptions">
        <video
          ref="fmVideo"
          controls
        >
          <source :src="videoSource">
        </video>
      </vue-plyr>
    </div>
  </b-modal>
</template>

<script>
import { BModal } from 'bootstrap-vue'
import VuePlyr from 'vue-plyr'
import translate from '@/file-manager/mixins/translate'
import modal from '../mixins/modal'
import 'vue-plyr/dist/vue-plyr.css'

export default {
  name: 'VideoPlayerModal',
  components: { VuePlyr, BModal },
  mixins: [modal, translate],
  data() {
    return {
      player: {},
      playerOptions: {
        tooltips: {
          controls: true,
          seek: true,
        },
        speed: {
          selected: 1,
          options: [0.5, 0.75, 1, 1.25, 1.5, 2],
        },
      },
      videoSource: '',
    }
  },
  computed: {
    /**
         * Selected disk
         * @returns {*}
         */
    selectedDisk() {
      return this.$store.getters['fm/selectedDisk']
    },

    /**
         * Video file
         * @returns {*}
         */
    videoFile() {
      return this.$store.getters['fm/selectedItems'][0]
    },
  },
  mounted() {
    // load source
    this.videoSource = `${this.$store.getters['fm/settings/baseUrl']}/stream-file?disk=${
      this.selectedDisk
    }&path=${encodeURIComponent(this.videoFile.path)}&token=${localStorage.getItem('accessToken')}`
  },
}
</script>
