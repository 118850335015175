<template>
  <div class="fm-info-block d-flex justify-content-between">
    <div class="col-auto">
      <span v-show="selectedCount">
        {{ `${lang.info.selected} ${selectedCount}` }}
        {{ `${lang.info.selectedSize} ${selectedFilesSize}` }}
      </span>
      <span v-show="!selectedCount">
        {{ `${lang.info.directories} ${directoriesCount}` }}
        {{ `${lang.info.files} ${filesCount}` }}
        {{ `${lang.info.size} ${filesSize}` }}
      </span>
    </div>
    <div class="col-4">
      <!-- Progress Bar -->
      <div
        v-show="progressBar"
        class="progress"
      >
        <div
          class="progress-bar progress-bar-striped bg-info"
          role="progressbar"
          :aria-valuenow="progressBar"
          aria-valuemin="0"
          aria-valuemax="100"
          :style="{ width: progressBar + '%' }"
        >
          {{ progressBar }}%
        </div>
      </div>
    </div>
    <div class="col-auto text-right">
      <div
        v-show="loadingSpinner"
        class="spinner-border spinner-border-sm text-info"
        role="status"
      />
      <span
        v-show="clipboardType"
        v-b-tooltip.hover="[lang.clipboard.title + ' - ' + lang.clipboard[clipboardType]]"
        @click="showModal('ClipboardModal')"
      >
        <i class="bi bi-clipboard" />
      </span>
      <span
        v-b-tooltip.hover="lang.modal.status.title"
        :class="[hasErrors ? 'text-danger' : 'text-success']"
        @click="showModal('StatusModal')"
      >
        <i class="bi bi-info-circle-fill" />
      </span>
    </div>
  </div>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue'
import translate from '@/file-manager/mixins/translate'
import helper from '@/file-manager/mixins/helper'

export default {
  name: 'InfoBlock',
  directives: { 'b-tooltip': VBTooltip },
  mixins: [translate, helper],
  computed: {
    /**
         * Active manager
         * @returns {any}
         */
    activeManager() {
      return this.$store.state.fm.activeManager
    },

    /**
         * Progress bar value - %
         * @returns {number}
         */
    progressBar() {
      return this.$store.state.fm.messages.actionProgress
    },

    /**
         * App has errors
         * @returns {boolean}
         */
    hasErrors() {
      return !!this.$store.state.fm.messages.errors.length
    },

    /**
         * Files count in selected directory
         * @returns {*}
         */
    filesCount() {
      return this.$store.getters[`fm/${this.activeManager}/filesCount`]
    },

    /**
         * Directories count in selected directory
         * @returns {*}
         */
    directoriesCount() {
      return this.$store.getters[`fm/${this.activeManager}/directoriesCount`]
    },

    /**
         * Files size in selected directory
         * @returns {*|string}
         */
    filesSize() {
      return this.bytesToHuman(this.$store.getters[`fm/${this.activeManager}/filesSize`])
    },

    /**
         * Count files and folders
         * @returns {*}
         */
    selectedCount() {
      return this.$store.getters[`fm/${this.activeManager}/selectedCount`]
    },

    /**
         * Calculate selected files size
         * @returns {*|string}
         */
    selectedFilesSize() {
      return this.bytesToHuman(this.$store.getters[`fm/${this.activeManager}/selectedFilesSize`])
    },

    /**
         * Clipboard - action type
         * @returns {null}
         */
    clipboardType() {
      return this.$store.state.fm.clipboard.type
    },

    /**
         * Spinner
         * @returns {number}
         */
    loadingSpinner() {
      return this.$store.state.fm.messages.loading
    },
  },
  methods: {
    /**
         * Show modal window
         * @param modalName
         */
    showModal(modalName) {
      this.$store.commit('fm/modal/setModalState', {
        modalName,
        show: true,
      })
    },
  },
}
</script>
