<template>
  <div class="fm-additions-file-list">
    <div
      v-for="(item, index) in selectedItems"
      :key="index"
      class="d-flex justify-content-between"
    >
      <div class="w-75 text-truncate">
        <span v-if="item.type === 'dir'"> <i class="bi bi-folder" />{{ item.basename }} </span>
        <span v-else>
          <i
            class="bi"
            :class="extensionToIcon(item.extension)"
          /> {{ item.basename }}
        </span>
      </div>
      <div
        v-if="item.type === 'file'"
        class="text-end"
      >
        {{ bytesToHuman(item.size) }}
      </div>
    </div>
  </div>
</template>

<script>
import helper from '@/file-manager/mixins/helper'

export default {
  name: 'SelectedFileList',
  mixins: [helper],
  computed: {
    /**
         * Selected files and folders
         * @returns {*}
         */
    selectedItems() {
      return this.$store.getters['fm/selectedItems']
    },
  },
}
</script>

<style lang="scss">
.fm-additions-file-list {
    .bi {
        padding-right: 0.5rem;
    }
}
</style>
