<template>
  <div class="fm-navbar mb-3">
    <div class="row justify-content-between">
      <div class="col-auto">
        <div
          class="btn-group"
          role="group"
        >
          <button
            v-b-tooltip.hover
            :title="backDisabled ? '' : lang.btn.back"
            type="button"
            class="btn btn-primary"
            :disabled="backDisabled"
            @click="historyBack()"
          >
            <i class="bi bi-skip-backward-fill" />
          </button>
          <button
            v-b-tooltip.hover
            :title="forwardDisabled ? '' : lang.btn.forward"
            type="button"
            class="btn btn-primary"
            :disabled="forwardDisabled"
            @click="historyForward()"
          >
            <i class="bi bi-skip-forward-fill" />
          </button>
          <button
            v-b-tooltip.hover="lang.btn.refresh"
            type="button"
            class="btn btn-primary"
            @click="refreshAll()"
          >
            <i class="bi bi-arrow-repeat" />
          </button>
        </div>
        <div
          class="btn-group"
          role="group"
        >
          <button
            v-b-tooltip.hover="lang.btn.file"
            type="button"
            class="btn btn-primary"
            @click="showModal('NewFileModal')"
          >
            <i class="bi bi-file-earmark" />
          </button>
          <button
            v-b-tooltip.hover="lang.btn.folder"
            type="button"
            class="btn btn-primary"
            @click="showModal('NewFolderModal')"
          >
            <i class="bi bi-folder" />
          </button>
          <button
            v-if="uploading"
            v-b-tooltip.hover="lang.btn.upload"
            type="button"
            class="btn btn-primary"
            disabled
          >
            <i class="bi bi-upload" />
          </button>
          <button
            v-else
            v-b-tooltip.hover="lang.btn.upload"
            type="button"
            class="btn btn-primary"
            @click="showModal('UploadModal')"
          >
            <i class="bi bi-upload" />
          </button>
          <button
            v-b-tooltip.hover="lang.btn.delete"
            type="button"
            class="btn btn-primary"
            :disabled="!isAnyItemSelected"
            @click="showModal('DeleteModal')"
          >
            <i class="bi bi-trash" />
          </button>
        </div>
        <div
          class="btn-group"
          role="group"
        >
          <button
            v-b-tooltip.hover="lang.btn.copy"
            type="button"
            class="btn btn-primary"
            :disabled="!isAnyItemSelected"
            @click="toClipboard('copy')"
          >
            <i class="bi bi-files" />
          </button>
          <button
            v-b-tooltip.hover="lang.btn.cut"
            type="button"
            class="btn btn-primary"
            :disabled="!isAnyItemSelected"
            @click="toClipboard('cut')"
          >
            <i class="bi bi-scissors" />
          </button>
          <button
            v-b-tooltip.hover="lang.btn.paste"
            type="button"
            class="btn btn-primary"
            :disabled="!clipboardType"
            @click="paste"
          >
            <i class="bi bi-clipboard" />
          </button>
        </div>
        <div
          class="btn-group"
          role="group"
        >
          <button
            v-b-tooltip.hover="lang.btn.hidden"
            type="button"
            class="btn btn-primary"
            @click="toggleHidden"
          >
            <i
              class="bi"
              :class="[hiddenFiles ? 'bi-eye-fill' : 'bi-eye-slash-fill']"
            />
          </button>
        </div>
      </div>
      <div class="col-auto text-right">
        <div
          class="btn-group"
          role="group"
        >
          <button
            v-b-tooltip.hover="lang.btn.table"
            type="button"
            class="btn btn-primary"
            :class="[viewType === 'table' ? 'active' : '']"
            @click="selectView('table')"
          >
            <i class="bi bi-view-list" />
          </button>
          <button
            v-b-tooltip.hover="lang.btn.grid"
            type="button"
            class="btn btn-primary"
            :class="[viewType === 'grid' ? 'active' : '']"
            @click="selectView('grid')"
          >
            <i class="bi bi-grid" />
          </button>
        </div>
        <div
          class="btn-group"
          role="group"
        >
          <button
            v-b-tooltip.hover="lang.btn.fullScreen"
            type="button"
            class="btn btn-primary"
            :class="{ active: fullScreen }"
            @click="screenToggle"
          >
            <i class="bi bi-arrows-fullscreen" />
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VBTooltip } from 'bootstrap-vue'
import translate from '@/file-manager/mixins/translate'
import EventBus from '@/file-manager/emitter'

export default {
  name: 'NavbarBlock',
  directives: { 'b-tooltip': VBTooltip },
  mixins: [translate],
  computed: {
    /**
         * Active manager name
         * @returns {any}
         */
    activeManager() {
      return this.$store.state.fm.activeManager
    },

    /**
         * Back button state
         * @returns {boolean}
         */
    backDisabled() {
      return !this.$store.state.fm[this.activeManager].historyPointer
    },

    /**
         * Forward button state
         * @returns {boolean}
         */
    forwardDisabled() {
      return (
        this.$store.state.fm[this.activeManager].historyPointer
                === this.$store.state.fm[this.activeManager].history.length - 1
      )
    },

    /**
         * Is any files or directories selected?
         * @returns {boolean}
         */
    isAnyItemSelected() {
      return (
        this.$store.state.fm[this.activeManager].selected.files.length > 0
                || this.$store.state.fm[this.activeManager].selected.directories.length > 0
      )
    },

    /**
         * Manager view type - grid or table
         * @returns {any}
         */
    viewType() {
      return this.$store.state.fm[this.activeManager].viewType
    },

    /**
         * Upload files
         * @returns {boolean}
         */
    uploading() {
      return this.$store.state.fm.messages.actionProgress > 0
    },

    /**
         * Clipboard - action type
         * @returns {null}
         */
    clipboardType() {
      return this.$store.state.fm.clipboard.type
    },

    /**
         * Full screen status
         * @returns {any}
         */
    fullScreen() {
      return this.$store.state.fm.fullScreen
    },

    /**
         * Show or Hide hidden files
         * @returns {boolean}
         */
    hiddenFiles() {
      return this.$store.state.fm.settings.hiddenFiles
    },
  },
  methods: {
    /**
         * Refresh file manager
         */
    refreshAll() {
      this.$store.dispatch('fm/refreshAll')
    },

    /**
         * History back
         */
    historyBack() {
      this.$store.dispatch(`fm/${this.activeManager}/historyBack`)

      const path = this.$store.state.fm.manager?.history[this.$store.state.fm.manager?.historyPointer - 1]
      this.$store.dispatch('fm/tree/setSelectedDirectory', path)
    },

    /**
         * History forward
         */
    historyForward() {
      this.$store.dispatch(`fm/${this.activeManager}/historyForward`)
    },

    /**
         * Copy
         * @param type
         */
    toClipboard(type) {
      this.$store.dispatch('fm/toClipboard', type)

      // show notification
      if (type === 'cut') {
        EventBus.emit('addNotification', {
          status: 'success',
          message: this.lang.notifications.cutToClipboard,
        })
      } else if (type === 'copy') {
        EventBus.emit('addNotification', {
          status: 'success',
          message: this.lang.notifications.copyToClipboard,
        })
      }
    },

    /**
         * Paste
         */
    paste() {
      this.$store.dispatch('fm/paste')
    },

    /**
         * Set Hide or Show hidden files
         */
    toggleHidden() {
      this.$store.commit('fm/settings/toggleHiddenFiles')
    },

    /**
         * Show modal window
         * @param modalName
         */
    showModal(modalName) {
      // show selected modal
      this.$store.commit('fm/modal/setModalState', {
        modalName,
        show: true,
      })
    },

    /**
         * Select view type
         * @param type
         */
    selectView(type) {
      if (this.viewType !== type) this.$store.commit(`fm/${this.activeManager}/setView`, type)
    },

    /**
         * Full screen toggle
         */
    screenToggle() {
      const fm = document.getElementsByClassName('fm')[0]

      if (!this.fullScreen) {
        if (fm.requestFullscreen) {
          fm.requestFullscreen()
        } else if (fm.mozRequestFullScreen) {
          fm.mozRequestFullScreen()
        } else if (fm.webkitRequestFullscreen) {
          fm.webkitRequestFullscreen()
        } else if (fm.msRequestFullscreen) {
          fm.msRequestFullscreen()
        }
      } else if (document.exitFullscreen) {
        document.exitFullscreen()
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen()
      }

      this.$store.commit('fm/screenToggle')
    },
  },
}
</script>

<style lang="scss">
.fm-navbar {
    flex: 0 0 auto;

    .col-auto > .btn-group:not(:last-child) {
        margin-right: 0.4rem;
    }
}
</style>
