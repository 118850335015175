<template>
  <b-form-group
    :label="label"
    class="mb-0"
  >
    <b-form-checkbox-group
      v-model="input"
      :name="name"
      @input="$emit('input', input)"
    >
      <b-form-checkbox
        v-for="option, index in options"
        :key="option.value"
        :value="option.value"
        :disabled="option.disabled"
        :class="{ 'mt-1': index > 1 }"
        :style="`width: ${optionWidthPercentage}%`"
      >
        <h5 class="mb-0">
          {{ option.label }}
        </h5>
        <small v-if="option.details">{{ option.details }}</small>
      </b-form-checkbox>
    </b-form-checkbox-group>
  </b-form-group>
</template>

<script>
import { BFormGroup, BFormCheckboxGroup, BFormCheckbox } from 'bootstrap-vue'

export default {
  components: {
    BFormGroup,
    BFormCheckboxGroup,
    BFormCheckbox,
  },
  props: {
    label: {
      type: String,
      default: () => '',
    },
    options: {
      type: Array,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    optionWidthPercentage: {
      type: Number,
      default: () => 45,
    },
    value: {
      type: Array,
      default: () => null,
    },
  },
  data() {
    return {
      input: [],
    }
  },
  watch: {
    value: {
      handler(val) {
        if (val && val !== this.input) this.input = val
      },
    },
  },
}
</script>
