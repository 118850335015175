<template>
  <div>
    <b-modal
      id="share-item-modal"
      :title="`${lang.modal.share.title} ${selectedItem && selectedItem.extension ? lang.modal.share.file : lang.modal.share.folder
      }`"
      visible
      centered
      no-close-on-backdrop
      @hidden="() => {
        hideModal();
        clearModal();
      }
      "
    >
      <b-overlay :show="isLoading">
        <b-alert
          show
          class="p-1"
        >
          <feather-icon icon="Link2Icon" />
          <span
            v-if="selectedItem"
            class="ml-1"
          >
            {{ selectedItem.extension ? lang.modal.share.file : lang.modal.share.folder }}:
            {{ selectedItem.basename }}
          </span>
        </b-alert>
        <validation-observer ref="shareItemForm">
          <custom-button-radio
            v-model="form.shareType"
            :label="lang.modal.share.shareWith"
            :options="shareTypes"
            :value="form.shareType"
            :disabled="checkingSharedUsers"
            name="share-type"
            icon-type="fa"
          />

          <validation-provider
            v-if="Number(form.shareType) === 0"
            #default="{ errors }"
            name="share with"
            rules="required"
          >
            <b-form-group>
              <v-select
                v-model="form.user"
                :options="projectUsersOptions"
                :class="{ 'is-invalid': errors[0] }"
                :placeholder="lang.modal.share.selectUser"
                :reduce="(user) => user.value"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="{ errors }"
            name="permissions"
            rules="required"
          >
            <checkbox-form
              v-model="form.permissions"
              :label="lang.modal.share.sharePermissions"
              :options="permissionOptions"
              :value="form.permissions"
              name="share-permission"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </validation-observer>

        <b-card
          v-if="sharedWith.length || checkingSharedUsers"
          bg-variant="light-primary"
          class="mt-2"
        >
          <!-- Share With -->
          <div
            v-if="sharedWith.length"
            class="mb-1"
          >
            <div class="d-flex justify-content-between">
              <h5 class="mb-0">
                {{ lang.modal.share.sharedWith }}
              </h5>
              <b-badge
                v-if="publiclyShared.length"
                v-b-tooltip.hover="{
                  title: copied ? lang.modal.share.linkCopied : lang.modal.share.copyLink,
                  variant: copied ? 'wz-teal' : '',
                }"
                variant="wz-teal"
                class="cursor-pointer"
                @click="copyLink"
              >
                <feather-icon :icon="copied ? 'CheckCircleIcon' : 'CopyIcon'" />
              </b-badge>
            </div>
            <small class="text-muted">{{ lang.modal.share.shareDetails }}</small>
          </div>

          <div class="d-flex">
            <!-- Shared With Users -->
            <div
              v-for="user in privatelySharedWith"
              :id="`shared-user-${user.email}`"
              :key="user.share_id"
              class="mr-1 position-relative cursor-pointer"
              @mouseenter="hoveredUser = user.email"
              @mouseleave="hoveredUser = null"
              @click="toggleShareDetails(user)"
            >
              <b-badge
                v-if="hoveredUser === user.email"
                v-b-tooltip.hover="lang.modal.share.revokeShare"
                variant="danger"
                class="unlink-icon position-absolute cursor-pointer z-1"
                pill
                @click.stop="showRevokeConfirmModal(user)"
              >
                <i class="fas fa-unlink" />
              </b-badge>
              <b-avatar
                v-b-tooltip.bottom.hover="user.name"
                variant="primary"
                :src="getAvatar(user.avatar)"
                size="48"
              />

              <share-details
                :selected-user="selectedUser"
                :granted-permissions="grantedPermissions"
                :permission-options="permissionOptions"
                :target="`shared-user-${user.email}`"
                @close="toggleShareDetails()"
              />
            </div>
            <!-- Shared Publicly -->
            <div
              v-if="publiclyShared.length"
              id="public-share"
              class="position-relative cursor-pointer"
              @mouseenter="hoveredUser = 'public'"
              @mouseleave="hoveredUser = null"
              @click="toggleShareDetails()"
            >
              <b-badge
                v-if="hoveredUser === 'public'"
                v-b-tooltip.hover="lang.modal.share.revokeShare"
                variant="danger"
                class="unlink-icon position-absolute cursor-pointer rounded-circle z-1"
                @click.stop="showRevokeConfirmModal()"
              >
                <i class="fas fa-unlink" />
              </b-badge>
              <b-avatar
                v-b-tooltip.bottom.hover="lang.modal.share.anyoneWithLink"
                variant="primary"
                size="48"
              >
                <i class="fas fa-link" />
              </b-avatar>

              <share-details
                :selected-user="selectedUser"
                :granted-permissions="grantedPermissions"
                :permission-options="permissionOptions"
                :target="'public-share'"
                @close="toggleShareDetails()"
              />
            </div>
          </div>

          <searching-component
            v-if="checkingSharedUsers"
            :text="lang.modal.share.checkingSharedUsers"
          />
        </b-card>
      </b-overlay>

      <template #modal-footer>
        <div class="w-100 d-flex justify-content-end">
          <div class="d-flex">
            <b-button
              class="mx-1"
              @click="$bvModal.hide('share-item-modal')"
            >
              {{ lang.btn.cancel }}
            </b-button>

            <b-button
              v-b-modal.share-file-modal
              variant="primary"
            >
              {{ lang.btn.share }}
            </b-button>
          </div>
        </div>
      </template>
    </b-modal>

    <!-- Share Confirm Modal -->
    <confirm-modal
      v-if="selectedItem"
      modal="share-file-modal"
      :title="`Share ${selectedItem && selectedItem.extension ? lang.modal.share.file : lang.modal.share.folder
      }`"
      :alert="`Are you sure you want to share '${selectedItem.basename}' with ${selectedUser ? selectedUser.name : 'public'
      } with these permissions?`"
      alert-variant="wz-teal"
      :ok-disabled="isLoading"
      :cancel-disabled="isLoading"
      @confirm="share"
    />

    <!-- Revoke Share Confirm Modal -->
    <confirm-modal
      v-if="selectedItem"
      modal="revoke-file-share-modal"
      title="Revoke File Sharing"
      :alert="`Are you sure you want to revoke sharing '${selectedItem.basename
      }' with ${selectedUser && selectedUser.name ? selectedUser.name : 'others'}?`"
      alert-variant="danger"
      ok-title="Revoke Share"
      ok-variant="danger"
      icon="AlertTriangleIcon"
      icon-color="danger"
      :ok-disabled="isLoading"
      :cancel-disabled="isLoading"
      @confirm="revokeFileShare"
    />
  </div>
</template>

<script>
import translate from '@/file-manager/mixins/translate'
import vSelect from 'vue-select'
import {
  BFormGroup,
  BAlert,
  BModal,
  BAvatar,
  BOverlay,
  BBadge,
  BButton,
  BCard,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import CustomButtonRadio from '@/views/common/components/CustomButtonRadio.vue'
import CheckboxForm from '@/views/common/components/CheckboxForm.vue'
import ConfirmModal from '@/views/Wallet/components/reusables/ConfirmModal.vue'
import { getUserData } from '@/auth/utils'
import helper from '@/file-manager/mixins/helper'
import Constants from '@/file-manager/constants'
import SearchingComponent from '@/views/common/components/SearchingComponent.vue'
import modal from '../mixins/modal'
import ShareDetails from './components/ShareDetails.vue'

const { PERMISSIONS } = Constants

export default {
  name: 'ShareModal',
  components: {
    vSelect,
    BFormGroup,
    ValidationObserver,
    ValidationProvider,
    BAlert,
    BModal,
    BAvatar,
    BOverlay,
    BBadge,
    BButton,
    BCard,
    SearchingComponent,
    ConfirmModal,
    CustomButtonRadio,
    CheckboxForm,
    ShareDetails,
  },
  mixins: [helper, modal, translate],
  data() {
    return {
      self: getUserData(),
      form: {
        permissions: [],
        shareType: 0,
      },
      directoryExist: false,
      fileExist: false,
      isLoading: false,
      checkingSharedUsers: false,
      copied: false,
      hoveredUser: null,
      selectedUser: null,
      showingShareDetails: false,
    }
  },
  computed: {
    permissionOptions() {
      return [
        {
          label: 'Read',
          value: 1,
          details: 'User can only view the file.',
          disabled: Boolean(this.form.permissions.length > 1),
          icon: 'BookOpenIcon',
        },
        {
          label: 'Update',
          value: 2,
          details: 'User can edit the file.',
          icon: 'Edit2Icon',
        },
        {
          label: 'Share',
          value: 16,
          details: 'User can share the file to others.',
          icon: 'Share2Icon',
        },
      ]
    },
    shareTypes() {
      return [
        { label: 'User', value: 0, icon: 'fas fa-user' },
        {
          label: 'Public',
          value: 3,
          icon: 'fas fa-link',
          disabled: !!this.publiclyShared.length,
          extra: this.publiclyShared.length ? 'Already Shared' : '',
        },
      ]
    },
    projectUsers() {
      return this.$store.state.project.projectUsers
    },
    selectedItem() {
      return this.$store.getters[`fm/${this.activeManager}/selectedList`][0]
    },
    projectUsersOptions() {
      return this.projectUsers
        ?.filter(user => user.email !== this.self.email)
        .filter(
          user => !this.privatelySharedWith
            .map(file => file.share_with)
            .includes(user.email),
        )
        .map(user => ({
          label: user.name,
          value: user.email,
        }))
    },
    sharedWith() {
      return this.$store.getters[`fm/${this.activeManager}/sharedWith`]
    },
    privatelySharedWith() {
      const shares = this.sharedWith.filter(file => file.share_with)
      return shares.map(share => {
        const userDetails = this.projectUsers.find(
          u => u.email === share.share_with,
        )
        return {
          share_id: share.id,
          avatar: userDetails?.avatar,
          name: userDetails?.name,
          email: userDetails?.email,
          ...share,
        }
      })
    },
    publiclyShared() {
      return this.sharedWith.filter(file => !file.share_with)
    },
    grantedPermissions() {
      return this.mapPermission(this.selectedUser?.permissions)
    },
  },
  watch: {
    'form.permissions': {
      handler(val) {
        if (val.length && !val.includes(PERMISSIONS.READ)) {
          this.form.permissions.push(PERMISSIONS.READ)
        }
      },
      deep: true,
    },
  },
  mounted() {
    // initiate item name
    this.name = this.selectedItem.basename
    this.getSharedUsers()
  },
  methods: {
    toggleShareDetails(sharedUser = null) {
      this.$root.$emit('bv::hide::popover')
      if (!this.showingShareDetails || sharedUser?.email !== this.selectedUser?.email || this.selectedUser.url) {
        if (sharedUser) {
          const userDetails = this.projectUsers.find(
            user => user.email === sharedUser.share_with,
          )

          this.selectedUser = {
            ...sharedUser,
            email: userDetails.email,
            name: userDetails.name,
            avatar: userDetails.avatar,
          }
        } else {
          const [selectedUser] = this.publiclyShared
          this.selectedUser = selectedUser
        }
        this.showingShareDetails = true
      } else {
        this.showingShareDetails = false
        this.selectedUser = null
      }
    },
    showRevokeConfirmModal(user = null) {
      this.$root.$emit('bv::hide::popover')
      this.showingShareDetails = false
      if (user) {
        // privately shared
        this.selectedUser = user
      } else {
        // publicly shared
        // eslint-disable-next-line prefer-destructuring
        this.selectedUser = this.publiclyShared[0]
      }
      this.$nextTick(() => {
        this.$bvModal.show('revoke-file-share-modal')
      })
    },
    copyLink() {
      navigator.clipboard.writeText(this.publiclyShared[0].url)
      this.copied = true
      setTimeout(() => {
        this.copied = false
      }, 5000)
    },
    clearModal() {
      this.$store.dispatch(`fm/${this.activeManager}/clearSharedUsers`)
    },
    getSharedUsers() {
      this.checkingSharedUsers = true
      const file = encodeURI(`/${this.selectedItem.path}`)
      this.$store
        .dispatch(`fm/${this.activeManager}/getSharedUsers`, { file })
        .then(() => {
          this.checkingSharedUsers = false
        })
    },

    /**
     * Share item
     */
    share() {
      // create new name with path
      this.$refs.shareItemForm.validate().then(success => {
        if (success) {
          this.isLoading = true

          const data = {
            ...this.form,
            permissions: this.form.permissions.reduce((acc, it) => acc + it, 0),
            file: this.selectedItem.path,
          }

          this.$store
            .dispatch('fm/share', {
              data,
            })
            .then(response => {
              if (response.data?.data?.url) {
                navigator.clipboard.writeText(response.data.data.url)
                this.showInfoMessage({
                  title: 'Link Copied',
                  message: 'Link copied to the clipboard!',
                })
              }
              this.showSuccess('File shared successfully!')

              this.$bvModal.hide('share-item-modal')
              // close modal window
              this.hideModal()
            })
            .finally(() => {
              this.isLoading = false
            })
        }
      })
    },
    revokeFileShare() {
      this.isLoading = true
      this.$store
        .dispatch('fm/revokeShare', {
          shareId: this.selectedUser.share_id ?? this.selectedUser.id,
        })
        .then(() => {
          this.showSuccess(
            `File sharing with ${this.selectedUser.name ?? 'public'} revoked`,
          )
          if (this.selectedUser.share_with) {
            const sharedUsers = this.sharedWith.filter(
              share => share.share_with !== this.selectedUser.email,
            )
            this.$store.dispatch(`fm/${this.activeManager}/setSharedUsers`, {
              sharedUsers,
            })
          }
          this.isLoading = false
        })
    },
    updateForm(user) {
      this.form = {
        permissions: this.mapPermissionCodes(user.permissions),
        user: user.email,
        shareType: user.share_type,
      }
      this.selectedUser = user
    },
    resetForm() {
      this.selectedUser = null
      this.$refs.shareItemForm.reset()
      this.form.permissions = []
      this.form.user = null
    },
  },
}
</script>

<style lang="scss" scoped>
.unlink-icon {
  top: -4px;
  right: -4px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;

  .fas {
    font-size: 10px;
  }
}
</style>
