<template>
  <b-modal
    id="upload-file-modal"
    :title="lang.modal.upload.title"
    visible
    centered
    ok-title="Upload"
    :ok-disabled="!countFiles"
    @ok="uploadFiles"
    @cancel="hideModal"
  >
    <div
      v-show="!progressBar"
      class="fm-btn-wrapper"
    >
      <label
        for="file-upload"
        class="w-100"
      >
        <div
          type="button"
          class="btn btn-primary btn-block"
        >
          {{ lang.btn.uploadSelect }}
        </div>
      </label>
      <input
        id="file-upload"
        class="d-none"
        type="file"
        multiple
        name="myfile"
        @change="selectFiles($event)"
      >
    </div>
    <div
      v-if="countFiles"
      class="fm-upload-list"
    >
      <div
        v-for="(item, index) in newFiles"
        :key="index"
        class="d-flex justify-content-between"
      >
        <div class="w-75 text-truncate">
          <i
            class="bi"
            :class="mimeToIcon(item.type)"
          />
          {{ item.name }}
        </div>
        <div class="text-right">
          {{ bytesToHuman(item.size) }}
        </div>
      </div>
      <hr>
      <div class="d-flex justify-content-between">
        <div>
          <strong>{{ lang.modal.upload.selected }}</strong>
          {{ newFiles.length }}
        </div>
        <div class="text-right">
          <strong>{{ lang.modal.upload.size }}</strong>
          {{ allFilesSize }}
        </div>
      </div>
      <hr>
      <div class="d-flex justify-content-between">
        <div>
          <strong>{{ lang.modal.upload.ifExist }}</strong>
        </div>
        <div class="form-check form-check-inline">
          <input
            id="uploadRadio1"
            class="form-check-input"
            type="radio"
            name="uploadOptions"
            :checked="!overwrite"
            @change="overwrite = 0"
          >
          <label
            class="form-check-label"
            for="uploadRadio1"
          >
            {{ lang.modal.upload.skip }}
          </label>
        </div>
        <div class="form-check form-check-inline">
          <input
            id="uploadRadio2"
            class="form-check-input"
            type="radio"
            name="uploadOptions"
            :checked="overwrite"
            @change="overwrite = 1"
          >
          <label
            class="form-check-label"
            for="uploadRadio2"
          >
            {{ lang.modal.upload.overwrite }}
          </label>
        </div>
      </div>
      <hr>
    </div>
    <div v-else>
      <p>{{ lang.modal.upload.noSelected }}</p>
    </div>
    <div class="fm-upload-info">
      <!-- Progress Bar -->
      <div
        v-show="countFiles"
        class="progress"
      >
        <div
          class="progress-bar progress-bar-striped bg-info"
          role="progressbar"
          :aria-valuenow="progressBar"
          aria-valuemin="0"
          aria-valuemax="100"
          :style="{ width: progressBar + '%' }"
        >
          {{ progressBar }}%
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { BModal } from 'bootstrap-vue'
import translate from '@/file-manager/mixins/translate'
import helper from '@/file-manager/mixins/helper'
import modal from '../mixins/modal'

export default {
  name: 'UploadModal',
  components: { BModal },
  mixins: [modal, translate, helper],
  data() {
    return {
      newFiles: [],
      overwrite: 0,
    }
  },
  computed: {
    /**
         * Progress bar value - %
         * @returns {number}
         */
    progressBar() {
      return this.$store.state.fm.messages.actionProgress
    },

    /**
         * Count of files selected for upload
         * @returns {number}
         */
    countFiles() {
      return this.newFiles.length
    },

    /**
         * Calculate the size of all files
         * @returns {*|string}
         */
    allFilesSize() {
      let size = 0

      for (let i = 0; i < this.newFiles.length; i += 1) {
        size += this.newFiles[i].size
      }

      return this.bytesToHuman(size)
    },
  },
  methods: {
    /**
         * Select file or files
         * @param event
         */
    selectFiles(event) {
      // files selected?
      if (event.target.files.length === 0) {
        // no file selected
        this.newFiles = []
      } else {
        // we have file or files
        this.newFiles = event.target.files
      }
    },

    /**
         * Upload new files
         */
    uploadFiles() {
      // if files exists
      if (this.countFiles) {
        // upload files
        this.$store
          .dispatch('fm/upload', {
            files: this.newFiles,
            overwrite: this.overwrite,
          })
          .then(response => {
            if (response.data.result.status === 'success') {
              this.hideModal()
            }
          })
      }
    },
  },
}
</script>

<style lang="scss">
.fm-modal-upload {
    .fm-btn-wrapper {
        position: relative;
        overflow: hidden;
        padding-bottom: 6px;
        margin-bottom: 0.6rem;
    }

    .fm-btn-wrapper input[type='file'] {
        font-size: 100px;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        cursor: pointer;
    }

    .fm-upload-list .bi {
        padding-right: 0.5rem;
    }

    .fm-upload-list .form-check-inline {
        margin-right: 0;
    }

    .fm-upload-info > .progress {
        margin-bottom: 1rem;
    }
}
</style>
