<template>
  <div class="searching d-flex align-items-center justify-content-center mr-3">
    <lottie-animation
      class="col-4"
      :animation-data="require(theme === 'light' ? '@/assets/animations/Searching.json' : '@/assets/animations/searching_dark.json')"
      :loop="true"
      :auto-play="true"
    />
    <p class="text text-dark mb-0">
      {{ text }}
    </p>
  </div>
</template>

<script>
import LottieAnimation from 'lottie-web-vue'

export default {
  components: {
    LottieAnimation,
  },
  props: {
    text: {
      type: String,
      default: () => '',
    },
  },
  computed: {
    theme() {
      return localStorage.getItem('vuexy-skin')
    },
  },
}
</script>

<style lang="scss" scoped>
.searching {
  height: 72px;
  .text {
    position: relative;
    right: 24px;
  }
}
</style>
