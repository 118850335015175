<template>
  <tree-skeleton :loading="isLoading && !subDirectories.length">
    <ul class="list-unstyled fm-tree-branch">
      <li
        v-for="(directory, index) in subDirectories"
        :key="index"
      >
        <p
          class="unselectable"
          :class="{ selected: selectedDirectory === directory.path }"
          @click="selectDirectory(directory.path)"
        >
          <span v-if="directory.props.hasSubdirectories">
            <b-spinner
              v-if="processingIndex === index"
              small
              variant="secondary"
              :style="'margin-right: 4px;'"
            />
            <i
              v-else
              class="bi"
              :class="[arrowState(index) ? 'bi-dash-square' : 'bi-plus-square']"
              @click.stop="showSubdirectories(directory.path, directory.props.showSubdirectories, index)"
            />
          </span>
          <i
            v-else
            class="bi bi-dash"
          />
          {{ directory.basename }}
        </p>

        <transition name="fade-tree">
          <tree-branch
            v-show="arrowState(index)"
            v-if="directory.props.hasSubdirectories"
            :parent-id="directory.id"
          />
        </transition>
      </li>
    </ul>
  </tree-skeleton>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { BSpinner } from 'bootstrap-vue'
import TreeSkeleton from '../skeleton/TreeSkeleton.vue'

export default {
  name: 'TreeBranch',
  components: {
    BSpinner,
    TreeSkeleton,
  },
  props: {
    parentId: { type: Number, required: true },
  },
  data() {
    return {
      processingIndex: null,
    }
  },
  computed: {
    ...mapGetters('fm/tree', ['treeLoading', 'isSubdirectoriesShown', 'selectedDirectory']),
    isLoading() {
      return this.$store.state.fm.messages.loading !== 0
    },
    /**
         * Subdirectories for selected parent
         * @returns {*}
         */
    subDirectories() {
      return this.$store.getters['fm/tree/directories'].filter(item => item.parentId === this.parentId)
    },
    isSubdirectoriesShown() {
      if (this.processingIndex !== null) {
        return this.$store.getters['fm/tree/isSubdirectoriesShown'](this.processingIndex)
      }
      return null
    },
  },
  watch: {
    isSubdirectoriesShown(val) {
      if (val) this.processingIndex = null
    },
  },
  methods: {
    ...mapActions('fm/tree', ['setSelectedDirectory']),
    /**
         * Check, is this directory selected?
         * @param path
         * @returns {boolean}
         */
    isDirectorySelected(path) {
      return this.$store.state.fm.left.selectedDirectory === path
    },

    /**
         * Show subdirectories - arrow
         * @returns {boolean}
         * @param index
         */
    arrowState(index) {
      return this.subDirectories[index].props.showSubdirectories
    },

    /**
         * Show/Hide subdirectories
         * @param path
         * @param showState
         */
    showSubdirectories(path, showState, index) {
      if (showState) {
        // hide
        this.$store.dispatch('fm/tree/hideSubdirectories', path)
      } else {
        this.processingIndex = index
        // show
        this.$store.dispatch('fm/tree/showSubdirectories', path)
      }
    },

    /**
         * Load selected directory and show files
         * @param path
         */
    selectDirectory(path) {
      // only if this path not selected
      this.setSelectedDirectory(path)
      if (!this.isDirectorySelected(path)) {
        this.$store.dispatch('fm/left/selectDirectory', { path, history: true })
      }
    },
  },
}
</script>
