<template>
  <b-overlay :show="isProcessing">
    <b-modal
      id="new-file-modal"
      :title="lang.modal.newFile.title"
      visible
      centered
      :ok-title="lang.btn.create"
      :ok-disabled="!submitActive || isProcessing"
      :cancel-disabled="isProcessing"
      @ok="addFile"
      @hidden="hideModal"
    >
      <validation-observer ref="fmCreateFileForm">
        <validation-provider
          #default="{ errors }"
          name="filename"
          rules="required|unique"
        >
          <b-form-group :label="lang.modal.newFile.fieldName">
            <b-input-group>
              <template #append>
                <b-form-input
                  v-model="extension"
                  class="w-50"
                  placeholder="Extension"
                  @keypress.enter="onSubmit"
                />
              </template>
              <b-form-input
                v-model="fileName"
                v-focus
                placeholder="File Name"
                :class="{ 'is-invalid': fileExist }"
                @keyup="validateFileName"
                @keypress.enter="onSubmit"
              />
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </b-form-group>
        </validation-provider>
      </validation-observer>
    </b-modal>

    <confirm-modal
      modal="confirm-create-file-modal"
      :title="lang.modal.newFile.warning"
      :alert="lang.modal.newFile.noExtensionWarning"
      alert-variant="danger"
      icon="AlertTriangleIcon"
      icon-color="danger"
      icon-size="64"
      @confirm="addFile"
    />
  </b-overlay>
</template>

<script>
import {
  BOverlay, BFormGroup, BInputGroup, BFormInput, BModal,
} from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import translate from '@/file-manager/mixins/translate'
import store from '@/store'
import ConfirmModal from '@/views/Wallet/components/reusables/ConfirmModal.vue'
import modal from '../mixins/modal'

extend('unique', value => {
  if (store.getters[`fm/${store.state.fm.activeManager}/fileExist`](value)) {
    return 'The file already exists'
  }
  return true
})

export default {
  name: 'NewFileModal',
  components: {
    BOverlay,
    BFormGroup,
    BInputGroup,
    BFormInput,
    BModal,
    ConfirmModal,
    ValidationObserver,
    ValidationProvider,
  },
  mixins: [modal, translate],
  data() {
    return {
      isProcessing: false,
      // name for new file
      fileName: '',
      extension: '.txt',

      // file exist
      fileExist: false,
    }
  },
  computed: {
    /**
         * Submit button - active or no
         * @returns {string|boolean}
         */
    submitActive() {
      return this.fileName && !this.fileExist
    },
  },
  methods: {
    /**
         * Check the file name if it exists or not.
         */
    validateFileName() {
      if (this.fileName) {
        this.fileExist = this.$store.getters[`fm/${this.activeManager}/fileExist`](this.fileName)
      } else {
        this.fileExist = false
      }
    },

    onSubmit() {
      if (this.extension) this.addFile()
      else this.$bvModal.show('confirm-create-file-modal')
    },

    /**
         * Create new file
         */
    addFile() {
      this.$refs.fmCreateFileForm.validate().then(success => {
        if (success) {
          const regex = /^\./
          this.isProcessing = true
          const extension = regex.test(this.extension) ? this.extension : `.${this.extension}`
          const filename = `${this.fileName}${extension}`
          this.$store.dispatch('fm/createFile', filename).then(response => {
            if (response.data.result.status === 'success') {
              this.hideModal()
            }
          }).finally(() => {
            this.isProcessing = false
          })
        }
      })
    },
  },
}
</script>
