<template>
  <div class="modal-content fm-modal-text-edit">
    <div class="modal-header">
      <h5 class="modal-title w-75 text-truncate">
        {{ lang.modal.editor.title }}
        <small class="text-muted pl-3">{{ selectedItem.basename }}</small>
        <small
          v-if="!hasUpdatePermission"
          class="text-muted"
        >&nbsp;({{ lang.modal.editor.readOnly }})</small>
      </h5>
      <feather-icon
        icon="XIcon"
        class="cursor-pointer"
        @click="hideModal"
      />
    </div>
    <div class="modal-body">
      <b-overlay :show="isLoading">
        <codemirror
          ref="fmCodeEditor"
          :value="code"
          :options="cmOptions"
          :height="editorHeight"
          @input="onChange"
        />
      </b-overlay>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-primary"
        @click="updateFile"
      >
        {{ lang.btn.submit }}
      </button>
      <button
        type="button"
        class="btn btn-light"
        @click="hideModal"
      >
        {{ lang.btn.cancel }}
      </button>
    </div>
  </div>
</template>

<script>
import { codemirror } from 'vue-codemirror'
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/blackboard.css'
import 'codemirror/mode/shell/shell'
import 'codemirror/mode/css/css'
import 'codemirror/mode/sass/sass'
import 'codemirror/mode/htmlmixed/htmlmixed'
import 'codemirror/mode/javascript/javascript'
import 'codemirror/mode/vue/vue'
import 'codemirror/mode/markdown/markdown'
import 'codemirror/mode/xml/xml'
import 'codemirror/mode/clike/clike'
import 'codemirror/mode/php/php'
import 'codemirror/mode/sql/sql'
import 'codemirror/mode/lua/lua'
import 'codemirror/mode/perl/perl'
import 'codemirror/mode/python/python'
import 'codemirror/mode/swift/swift'
import 'codemirror/mode/ruby/ruby'
import 'codemirror/mode/go/go'
import 'codemirror/mode/yaml/yaml'
import 'codemirror/mode/properties/properties'
import { BOverlay } from 'bootstrap-vue'
import translate from '@/file-manager/mixins/translate'
import contextMenu from '@/file-manager/components/blocks/mixins/contextMenu'
import modal from '../mixins/modal'

export default {
  name: 'TextEditModal',
  components: { codemirror, BOverlay },
  mixins: [modal, translate, contextMenu],
  data() {
    return {
      code: '',
      editedCode: '',
    }
  },
  computed: {
    isLoading() {
      return this.$store.state.fm.messages.loading !== 0
    },
    /**
         * Selected disk
         * @returns {*}
         */
    selectedDisk() {
      return this.$store.getters['fm/selectedDisk']
    },

    /**
         * Selected file
         * @returns {*}
         */
    selectedItem() {
      return this.$store.getters['fm/selectedItems'][0]
    },

    /**
         * Check permission to update the file
         * @returns {*}
         */
    hasUpdatePermission() {
      return this.canEdit(this.selectedItem.extension, this.selectedItem['share-permissions'])
    },

    /**
         * CodeMirror options
         * @returns {{mode: *, lineNumbers: boolean, line: boolean, theme: string}}
         */
    cmOptions() {
      return {
        mode: this.$store.state.fm.settings.textExtensions[this.selectedItem.extension],
        theme: 'blackboard',
        lineNumbers: true,
        line: true,
        lineWrapping: true,
        styleActiveLine: true,
        autofocus: true,
        readOnly: !this.hasUpdatePermission,
      }
    },

    /**
         * Calculate the height of the code editor
         * @returns {number}
         */
    editorHeight() {
      if (this.$store.state.fm.modal.modalBlockHeight) {
        return this.$store.state.fm.modal.modalBlockHeight - 200
      }

      return 300
    },
  },
  mounted() {
    // get file for edit
    this.$store
      .dispatch('fm/getFile', {
        disk: this.selectedDisk,
        path: this.selectedItem.path,
      })
      .then(response => {
        // add code
        if (this.selectedItem.extension === 'json') {
          this.code = JSON.stringify(response.data, null, 4)
        } else {
          this.code = response.data
        }
      })
  },
  methods: {
    /**
         * Update file
         */
    updateFile() {
      const formData = new FormData()
      // add disk name
      formData.append('disk', this.selectedDisk)
      // add path
      formData.append('path', this.selectedItem.dirname)
      // add updated file
      formData.append('file', new Blob([this.editedCode]), this.selectedItem.basename)

      this.$store.dispatch('fm/updateFile', formData).then(response => {
        if (response.data.result.status === 'success') {
          this.hideModal()
        }
      })
    },

    /**
         * Edited code
         * @param value
         */
    onChange(value) {
      this.editedCode = value
    },
  },
}
</script>

<style lang="scss">
.fm-modal-text-edit {
    .modal-body {
        padding: 0;
    }
}
</style>
