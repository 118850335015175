<template>
  <div class="fm-breadcrumb">
    <nav aria-label="breadcrumb">
      <ol
        class="breadcrumb"
        :class="[manager === activeManager ? 'active-manager' : 'bg-light']"
      >
        <li
          class="fm-breadcrumb-item"
          @click="selectMainDirectory"
        >
          <span class="badge bg-wz-teal">
            <i class="bi bi-hdd" />
          </span>
        </li>
        <li
          v-for="(item, index) in breadcrumb"
          :key="index"
          class="fm-breadcrumb-item fm-breadcrumb-items text-truncate"
          :class="[breadcrumb.length === index + 1 ? 'active' : '']"
          @click="selectDirectory(index)"
        >
          <span>{{ item }}</span>
        </li>
      </ol>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'BreadCrumb',
  props: {
    manager: { type: String, required: true },
  },
  computed: {
    /**
         * Active manager name
         * @returns {any}
         */
    activeManager() {
      return this.$store.state.fm.activeManager
    },

    /**
         * Selected Disk for this manager
         * @returns {any}
         */
    selectedDisk() {
      return this.$store.state.fm[this.manager].selectedDisk
    },

    /**
         * Selected directory for this manager
         * @returns {any}
         */
    selectedDirectory() {
      return this.$store.state.fm[this.manager].selectedDirectory
    },

    /**
         * Breadcrumb
         * @returns {*}
         */
    breadcrumb() {
      return this.$store.getters[`fm/${this.manager}/breadcrumb`]
    },
  },
  methods: {
    /**
         * Load selected directory
         * @param index
         */
    selectDirectory(index) {
      const path = this.breadcrumb.slice(0, index + 1).join('/')

      // only if this path not selected
      if (path !== this.selectedDirectory) {
        // load directory
        this.$store.dispatch(`fm/${this.manager}/selectDirectory`, { path, history: true })
      }
    },

    /**
         * Select main directory
         */
    selectMainDirectory() {
      if (this.selectedDirectory) {
        this.$store.dispatch(`fm/${this.manager}/selectDirectory`, { path: null, history: true })
      }
    },
  },
}
</script>
