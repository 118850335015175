<template>
  <b-overlay
    :show="isProcessing"
    class="modal-content fm-modal-folder"
  >
    <b-modal
      id="new-folder-modal"
      :title="lang.modal.newFolder.title"
      visible
      centered
      :ok-title="lang.btn.create"
      :ok-disabled="!submitActive || isProcessing"
      :cancel-disabled="isProcessing"
      @ok="addFolder"
      @hidden="hideModal"
    >
      <validation-observer ref="fmCreateFolderForm">
        <validation-provider
          #default="{ errors }"
          name="name"
          rules="required|unique"
        >
          <div class="form-group">
            <label for="fm-folder-name">{{ lang.modal.newFolder.fieldName }}</label>
            <input
              id="fm-folder-name"
              v-model="directoryName"
              v-focus
              type="text"
              :placeholder="lang.modal.newFolder.placeholder"
              class="form-control"
              :class="{ 'is-invalid': directoryExist }"
              @keyup="validateDirName"
              @keypress.enter="addFolder"
            >
            <small class="text-danger">{{ errors[0] }}</small>
          </div>
        </validation-provider>
      </validation-observer>
    </b-modal>
  </b-overlay>
</template>

<script>
import { BOverlay, BModal } from 'bootstrap-vue'
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import translate from '@/file-manager/mixins/translate'
import store from '@/store'
import modal from '../mixins/modal'

extend('unique', value => {
  if (store.getters[`fm/${store.state.fm.activeManager}/directoryExist`](value)) {
    return 'The folder already exists'
  }
  return true
})

export default {
  name: 'NewFolderModal',
  components: {
    BOverlay,
    BModal,
    ValidationObserver,
    ValidationProvider,
  },
  mixins: [modal, translate],
  data() {
    return {
      isProcessing: false,
      // name for new directory
      directoryName: '',

      // directory exist
      directoryExist: false,
    }
  },
  computed: {
    /**
         * Submit button - active or no
         * @returns {string|boolean}
         */
    submitActive() {
      return this.directoryName && !this.directoryExist
    },
  },
  methods: {
    /**
         * Check the folder name if it exists or not.
         */
    validateDirName() {
      if (this.directoryName) {
        this.directoryExist = this.$store.getters[`fm/${this.activeManager}/directoryExist`](
          this.directoryName,
        )
      } else {
        this.directoryExist = false
      }
    },

    /**
         * Create new directory
         */
    addFolder() {
      this.$refs.fmCreateFolderForm.validate().then(success => {
        if (success) {
          this.isProcessing = true
          this.$store.dispatch('fm/createDirectory', this.directoryName).then(response => {
            if (response.data.result.status === 'success') {
              this.hideModal()
            }
          }).finally(() => {
            this.isProcessing = false
          })
        }
      })
    },
  },
}
</script>
