<template>
  <div ref="scrollArea">
    <div
      class="fm-grid position-relative"
      :class="{'overflow-hidden': showLoader}"
    >
      <div class="d-flex align-content-start flex-wrap">
        <div
          v-if="!isRootPath"
          class="fm-grid-item text-center"
          @click="!showLoader ? levelUp() : ''"
        >
          <div class="fm-item-icon">
            <i class="bi bi-arrow-90deg-up pb-2" />
          </div>
          <div class="fm-item-info">
            <strong>..</strong>
          </div>
        </div>

        <div
          v-for="(directory, index) in directories"
          :key="`d-${index}`"
          class="fm-grid-item text-center unselectable position-relative"
          :title="directory.basename"
          :class="{ active: checkSelect('directories', directory.path) }"
          @click="selectItem('directories', directory.path, $event)"
          @dblclick.stop="selectDirectory(directory.path)"
          @contextmenu.prevent="contextMenu(directory, $event)"
        >
          <feather-icon
            v-if="directory['share-types'] || directory['mount-type'] === 'shared'"
            v-b-tooltip.hover="directory['owner-id'] !== self.email ? `Shared by ${directory['owner-display-name']}` : 'Shared Folder'"
            class="text-primary link-icon position-absolute right-0 top-0"
            icon="Share2Icon"
          />
          <div class="fm-item-icon text-primary">
            <i
              class="bi pb-2"
              :class="acl && directory.acl === 0 ? 'bi-lock' : 'bi-folder'"
            />
          </div>
          <div class="fm-item-info">
            {{ directory.basename }}
          </div>
        </div>

        <div
          v-for="(file, index) in files"
          :key="`f-${index}`"
          class="fm-grid-item text-center unselectable position-relative"
          :title="file.basename"
          :class="{ active: checkSelect('files', file.path) }"
          @click="selectItem('files', file.path, $event)"
          @dblclick="selectAction(file.path, file.extension)"
          @contextmenu.prevent="contextMenu(file, $event)"
        >
          <feather-icon
            v-if="file['share-types'] || file['mount-type'] === 'shared'"
            v-b-tooltip.hover="file['owner-id'] !== self.email ? `Shared by ${file['owner-display-name']}` : 'Shared File'"
            class="text-primary link-icon position-absolute right-0 top-0"
            icon="Share2Icon"
          />
          <div class="fm-item-icon text-primary">
            <i
              v-if="acl && file.acl === 0"
              class="bi bi-file-earmark-lock pb-2"
            />
            <thumbnail
              v-else-if="thisImage(file.extension)"
              :disk="disk"
              :file="file"
            />
            <i
              v-else
              class="bi pb-2"
              :class="extensionToIcon(file.extension)"
            />
          </div>
          <div class="fm-item-info">
            {{ `${file.filename}.${file.extension}` }}
            <br>
            {{ bytesToHuman(file.size) }}
          </div>
        </div>
      </div>
      <div
        v-if="showLoader"
        class="text-center"
      >
        <b-spinner />
      </div>
    </div>
  </div>
</template>

<script>
import { BSpinner, VBTooltip } from 'bootstrap-vue'
import translate from '@/file-manager/mixins/translate'
import helper from '@/file-manager/mixins/helper'
import Scrollbar from 'smooth-scrollbar'
import { mapGetters } from 'vuex'
import { getUserData } from '@/auth/utils'
import managerHelper from './mixins/manager'
import Thumbnail from './Thumbnail.vue'

export default {
  name: 'GridView',
  components: { Thumbnail, BSpinner },
  directives: { 'b-tooltip': VBTooltip },
  mixins: [translate, helper, managerHelper],
  props: {
    manager: { type: String, required: true },
  },
  data() {
    return {
      disk: '',
      scrollbar: {},
      self: getUserData(),
    }
  },
  computed: {
    ...mapGetters('fm/tree', ['selectedDirectory']),
    isLoading() {
      return this.$store.state.fm.messages.loading !== 0
    },
    showLoader() {
      return this.isLoading && !(this.directories.length || this.files.length)
    },
    /**
         * Image extensions list
         * @returns {*}
         */
    imageExtensions() {
      return this.$store.state.fm.settings.imageExtensions
    },
  },
  watch: {
    selectedDirectory() {
      this.scrollbar.scrollTo(0, 0)
    },
  },
  mounted() {
    this.disk = this.selectedDisk

    this.scrollbar = Scrollbar.init(this.$refs.scrollArea, {
      renderByPixels: true,
      continuousScrolling: true,
    })
  },
  beforeUpdate() {
    // if disk changed
    if (this.disk !== this.selectedDisk) {
      this.disk = this.selectedDisk
    }
  },
  beforeDestroy() {
    if (this.scrollbar) {
      this.scrollbar.destroy()
    }
  },
  methods: {
    /**
         * Check file extension (image or no)
         * @param extension
         * @returns {boolean}
         */
    thisImage(extension) {
      // extension not found
      if (!extension) return false

      return this.imageExtensions.includes(extension.toLowerCase())
    },
  },
}
</script>
