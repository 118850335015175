<template>
  <b-modal
    id="unzip-modal"
    :title="lang.modal.unzip.title"
    visible
    centered
    :ok-title="lang.btn.unzip"
    :ok-disabled="!submitActive"
    @ok="unpackArchive"
    @hidden="hideModal"
  >
    <div class="d-flex justify-content-between">
      <div>
        <strong>{{ lang.modal.unzip.fieldRadioName }}</strong>
      </div>
      <div class="form-check form-check-inline">
        <input
          id="unzipRadio1"
          class="form-check-input"
          type="radio"
          :checked="!createFolder"
          @change="createFolder = false"
        >
        <label
          class="form-check-label"
          for="unzipRadio1"
        >
          {{ lang.modal.unzip.fieldRadio1 }}
        </label>
      </div>
      <div class="form-check form-check-inline">
        <input
          id="unzipRadio2"
          class="form-check-input"
          type="radio"
          :checked="createFolder"
          @change="createFolder = true"
        >
        <label
          class="form-check-label"
          for="unzipRadio2"
        >
          {{ lang.modal.unzip.fieldRadio2 }}
        </label>
      </div>
    </div>
    <hr>
    <div
      v-if="createFolder"
      class="form-group"
    >
      <label for="fm-folder-name">{{ lang.modal.unzip.fieldName }}</label>
      <input
        id="fm-folder-name"
        v-model="directoryName"
        v-focus
        type="text"
        class="form-control"
        :class="{ 'is-invalid': directoryExist }"
        @keyup="validateDirName"
      >
      <div
        v-show="directoryExist"
        class="invalid-feedback"
      >
        {{ lang.modal.unzip.fieldFeedback }}
      </div>
    </div>
    <span
      v-else
      class="text-danger"
    >{{ lang.modal.unzip.warning }}</span>
  </b-modal>
</template>

<script>
import { BModal } from 'bootstrap-vue'
import translate from '@/file-manager/mixins/translate'
import modal from '../mixins/modal'

export default {
  name: 'UnzipModal',
  components: { BModal },
  mixins: [modal, translate],
  data() {
    return {
      createFolder: false,
      directoryName: '',
      directoryExist: false,
    }
  },
  computed: {
    /**
         * Submit button - active or no
         * @returns {string|boolean}
         */
    submitActive() {
      if (this.createFolder) {
        return this.directoryName && !this.directoryExist
      }

      return true
    },
  },
  methods: {
    /**
         * Check the folder name if it exists or not.
         */
    validateDirName() {
      if (this.directoryName) {
        this.directoryExist = this.$store.getters[`fm/${this.activeManager}/directoryExist`](
          this.directoryName,
        )
      } else {
        this.directoryExist = false
      }
    },

    /**
         * Unpack selected archive
         */
    unpackArchive() {
      this.$store.dispatch('fm/unzip', this.createFolder ? this.directoryName : null).then(() => {
        this.hideModal()
      })
    },
  },
}
</script>
